.video {
  width: 100%;
  padding: 3vw 5vw;
  min-height: 55vw;
  .videoContainer {
    height: 100%;
    width: 100%;
    .videoWrapper {
      position: relative;
      padding-bottom: 55%;
      height: 0;
      height: 0;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}