.hero {
  background-color: #D8D8D8;
  padding-top: 7vw;
  display: grid;
  width: 100%;
  overflow: hidden;
  height: 100vh;
  max-height: 56vw;
  position: relative;
  .backgroundImage {
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.1);
  }
  .scrollers {
    position: relative;
    opacity: 0;
    visibility: hidden;
    transition: all .25s ease-in-out;
    z-index: 1;
    .scrollContainer, .scrollContainer2 {
      opacity: 1;
      transition: all 1s;
      position: relative;
      &.hide {
        opacity: .5;
        pointer-events: none;
      }
      &.front {
        z-index: 3;
      }
      .attributes {
        display: flex;
        position: relative;
        .attribute {
          position: relative;
          white-space: nowrap;
          margin: 0 .5vw 0 .5vw;
          color: black;
          cursor: pointer;
          opacity: 1;
          position: relative;
          font-family: league-gothic;
          font-size: 6vw;
          line-height: 1.17;
          text-transform: uppercase;
          padding: 0;
          &.hide {
            opacity: 0;
            transition: all 1s;
            pointer-events: none;
          }
          &:hover {
            color: white;
          }
          .attributeText {
            opacity: 1;
            &.textHide {
              transition: all 1s;
              opacity: 0;
            }
          }
        }
      }
    }
    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
  .person {
    position: absolute;
    width: 100vw;
    height: 130%;
    left: 0vw;
    bottom: -10vw;
    opacity: 0;
    z-index: -1;
    transition: all .5s;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    &.show {
      opacity: 1;
      z-index: 0;
    }
  }
  .defaultText {
    position: absolute;
    // white-space: nowrap;
    text-shadow: 0px 0px 10px rgb(0, 0, 0, .2);
    letter-spacing: 3px;
    margin: 0 .5vw 0 .5vw;
    color: white;
    cursor: pointer;
    opacity: 1;
    font-family: league-gothic;
    font-size: 6vw;
    line-height: 1.17;
    text-transform: uppercase;
    padding: 0;
    left: 25vw;
    bottom: 20vw;
    opacity: 0;
    z-index: 1;
    transition: all .5s;
    &.show {
      opacity: 1;
      z-index: 1;
    }
  }
  .largeText {
    bottom: -4vw;
    font-family: league-gothic;
    font-size: 22vw;
    line-height: 1.17;
    text-transform: uppercase;
    color: white;
    opacity: .4;
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0;
    position: absolute;
    white-space: nowrap;
  }
  
  @media (max-width: 500px) {
    max-height: unset;
    height: unset;
    min-height: calc(100vh - 23vw);
    padding-top: 25vw;
    .scrollers {
      .scrollContainer2 {
        display: none;
      }
      .scrollContainer {
        .attributes {
          .attribute {
            font-size: 15vw;
          }
        }
      }
    }
    .defaultText {
      text-align: center;
      font-size: 15vw;
      left: 4vw;
      right: 4vw;
      bottom: 50vw;

    }
    .largeText {
      font-size: 26vw;
    }
  }
}
