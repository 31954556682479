.toggleField {
  position: absolute;
  left: 54.5vw;
  display: flex;
  z-index: 2;
  input[type='checkbox'] {
    opacity: 0;
    height: 0;
    width: 0;
  }
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    .optionOne, .optionTwo {
      font-family: myriad-pro;
      text-transform: uppercase;
      font-weight: 900;
      font-weight: 900;
      font-size: .8vw;
      color: #a5a5a5;
      padding-top: .1vw;
      margin: 0 .2vw;
      transition: all .25s ease-in-out;
      &.active {
        color: black;
      }
    }
    .toggle {
      width: 4vw;
      height: 1.4vw;
      border-radius: 2vw;
      background-color: #D8D8D8;
      text-align: left;
      position: relative;
      display: inline-block;
      &:before {
        background-color: black;
        height: 1.1vw;
        width: 1.1vw;
        border-radius: 100%;
        transition: all .5s ease-out;
        content: '';
        position: absolute;
        left: .15vw;
        top: .15vw;
      }
      &.active {
        &:before {
          transform: translateX(2.4vw);
        }
      }
    }
  }
  @media (max-width: 500px) {
    position: absolute;
    left: 57vw;
    z-index: 21;
    label {
      .label {
        font-size: 4vw;
      }
      .optionOne, .optionTwo {
        font-size: 3vw;
      }
      .toggle {
        width: 16vw;
        height: 5.5vw;
        border-radius: 8vw;
        background-color: #D8D8D8;
        text-align: left;
        margin-top: 0vw;
        position: relative;
        display: inline-block;
        &:before {
          background-color: black;
          height: 5vw;
          width: 5vw;
          border-radius: 100%;
          transition: all .5s ease-out;
          content: '';
          position: absolute;
          left: .6vw;
          top: .3vw;
        }
        &.active {
          &:before {
            transform: translateX(9.4vw);
          }
        }
      }
    }
  }
}