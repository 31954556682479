.slideContainer {
  width: 100%;
  height: 9vw;
  position: relative;
  .valueCircle {
    position: absolute;
    top: 1vw;
    .valueContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 1vw;
      border-radius: 100%;
      background-color: #0056b8;
      padding: 0.4vw;
      height: 2.4vw;
      width: 2.4vw;
      text-align: center;
      position: relative;
      &:after {
        position: absolute;
        content: "";
        border-left: 1px solid #0056b8;
        left: 50%;
        height: .6vw;
        width: 1vw;
        bottom: -.6vw;
      }
    }
  }
  .colorContainer {
    height: 5%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 4vw;
    left: 0;
    z-index: 1;
    background: #e4e2e2;
    .color {
      background-color: #0056b8;
      height: 100%;
      z-index: 1;
    }
  }
  .label {
    position: absolute;
    top: -2.5vw;
    color: black;
    font-size: 1.25vw;
    font-family: league-gothic;
    text-transform: uppercase;
  }
  .slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: .5vw;
    background: transparent;
    outline: none;
    border-radius: 1.5vw;
    position: absolute;
    z-index: 2;
    top: 4vw;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.2vw;
    height: 1.2vw;
    background: #0056b8;
    border-radius: 100%;
    box-shadow: 0 .2vw .5vw .1vw rgba(120, 121, 122, .35);
    cursor: pointer;
    z-index: 4;
  }
  
  .slider::-moz-range-thumb {
    width: 1.2vw;
    height: 1.2vw;
    background: #0056b8;
    border-radius: 100%;
    box-shadow: 0 .2vw .5vw .1vw rgba(120, 121, 122, .35);
    cursor: pointer;
    z-index: 4;
  }
  @media (max-width: 500px) {
    height: 18vw;
    width: 70%;
    .valueCircle {
      position: absolute;
      top: 2.3vw;
      .valueContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 3vw;
        border-radius: 100%;
        background-color: #0056b8;
        padding: .2vw;
        height: 7vw;
        width: 7vw;
        text-align: center;
        position: relative;
        &:after {
          position: absolute;
          content: "";
          border-left: 1px solid #0056b8;
          left: 50%;
          height: 1vw;
          width: 1vw;
          bottom: -.8vw;
        }
      }
    }
    .colorContainer {
      height: 5%;
      width: 100%;
      overflow: hidden;
      position: absolute;
      top: 12vw;
      left: 0;
      z-index: 1;
      background: #e4e2e2;
      .color {
        background-color: #0056b8;
        height: 100%;
        z-index: 1;
      }
    }
    .label {
      position: absolute;
      top: -2.5vw;
      color: black;
      font-size: 4vw;
      font-family: league-gothic;
    }
    .slider {
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      height: .5vw;
      background: transparent;
      outline: none;
      border-radius: 1.5vw;
      position: absolute;
      z-index: 2;
      top: 12vw;
    }
    
    .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 3vw;
      height: 3vw;
      background: #0056b8;
      border-radius: 100%;
      box-shadow: 0 .2vw .5vw .1vw rgba(120, 121, 122, .35);
      cursor: pointer;
      z-index: 4;
    }
    
    .slider::-moz-range-thumb {
      width: 3vw;
      height: 3vw;
      background: #0056b8;
      border-radius: 100%;
      box-shadow: 0 .2vw .5vw .1vw rgba(120, 121, 122, .35);
      cursor: pointer;
      z-index: 4;
    }
  }
}