.picker {
  position: relative;
  width: 100%;
  padding: 3% 12% 3% 12%;
  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 70%;
  }
  .heading {
    font-family: league-gothic;
    color: black;
    font-size: 2vw;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    margin-right: 20%;
    line-height: 1.5;
    margin-bottom: 1vw;
    transition: all .5s ease-in-out;
    opacity: 0;
    transform: translateX(-100px);
  }
  .subheading {
    font-family: myriad-pro;
    line-height: 1.5;
    color: #6D6D6D;
    font-size: 1.3vw;
    width: 100%;
    padding: 0 10%;
    text-align: center;
    margin-bottom: 6vw;
    transition: all .5s ease-in-out;
    opacity: 0;
    transform: translateX(-100px);
  }
  .items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .item {
      text-align: center;
      width: 10vw;
      height: 10vw;
      margin-bottom: 5vw;
      transition: all .5s ease-in-out;
      opacity: 0;
      transform: translateX(100px);
      a {
        width: 100%;
        height: 100%;
        img {
          width: 80%;
          height: 80%;
          object-fit: contain;
        }
        p {
          margin-top: 3vw;
          font-family: myriad-pro;
          font-weight: 900;
          color: black;
          font-size: 1.3vw;
          font-weight: 700;
          text-transform: uppercase;
        }
      }
      &:hover {
        a {
          img {
            transition: all .25s ease-in-out;
            transform: scale(1.1);
          }
        }
      }
    }
  }
  &.show {
    .heading, .subheading {
      opacity: 1;
      transform: translateX(0);
    }
    .items {
      .item {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  @media (max-width: 500px) {
    padding: 5% 8% 5% 8%;
    .heading {
      font-size: 5vw;
      margin-right: 0;
      margin-bottom: 2vw;
      padding: 0;
    }
    .subheading {
      font-size: 4vw;
      margin-bottom: 8vw;
      padding: 0;
    }
    .items {
      .item {
        margin-bottom: 20vw;
        width: 30vw;
        height: 30vw;
        a {
          p {
            font-size: 4vw;
            margin-top: 2vw;
          }
        }
      }
    }
  }
}