.menu {
  display: none;
  grid-auto-flow: column;
  gap: 2vw;
  align-items: center;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjNweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjMgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+REI5OTkzNjgtNEY0RS00ODgyLTlDMzItNDFDRTNGQjE5MUU2PC90aXRsZT4KICAgIDxnIGlkPSJNb2JpbGUtLS1jb21wb25lbnRzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4KICAgICAgICA8ZyBpZD0iWzEwLWZ1bm5lbHNdLS0iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zNjkuMDAwMDAwLCAtNDEuMDAwMDAwKSIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2Utd2lkdGg9IjMiPgogICAgICAgICAgICA8ZyBpZD0ibGlzdCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzcwLjAwMDAwMCwgNDMuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8bGluZSB4MT0iMSIgeTE9IjAiIHgyPSIyMCIgeTI9IjAiIGlkPSJTaGFwZSI+PC9saW5lPgogICAgICAgICAgICAgICAgPGxpbmUgeDE9IjEiIHkxPSI4IiB4Mj0iMjAiIHkyPSI4IiBpZD0iU2hhcGUiPjwvbGluZT4KICAgICAgICAgICAgICAgIDxsaW5lIHgxPSIxIiB5MT0iMTYiIHgyPSIyMCIgeTI9IjE2IiBpZD0iU2hhcGUiPjwvbGluZT4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+');
  background-color: #0466b2;
  background-size: 5.5vw;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  height: 10.4vw;
  width: 10.4vw;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  
  &:hover {
    background-color: #014070;
  }
  @media (max-width: 500px) {
    display: grid;
  }
}
