.megamenu {
  background-color: #0056B8;
  height: calc(100vh - 13vw);
  width: 100%;
  position: absolute;
  top: 6.4vw;
  left: 0;
  max-height: 0;
  transition: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  box-shadow: 0 0 10px 2px rgba(0,0,0,.1);
  &.two {
    grid-template-columns: 1fr 1fr;
  }
  &.show {
    max-height: calc(100vh - 7vw);
    transition: all .3s;
    overflow: auto;
  }
  .heading {
    font-family: myriad-pro;
    font-weight: 900;
    color: black;
    font-size: 1.5vw;
    line-height: 1.5;
    text-transform: uppercase;
  }
  .close {
    position: absolute;
    top: 1.5vw;
    right: 3vw;
    color: white;
    font-family: myriad-pro;
    font-weight: 900;
    font-size: 2.1vw;
    z-index: 2;
    text-shadow: 2px 2px black;
  }
  overflow: hidden;
  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 4.7vw 5vw 2vw 2vw;
    transform: translateX(-100%);
    transition: none;
    &.show {
      transform: translateX(0%);
      transition: all .3s .2s;
    }
    .option {
      font-family: myriad-pro;
      font-weight: 900;
      color: white;
      font-size: 2.5vw;
      line-height: 1.5;
      text-transform: uppercase;
      cursor: pointer;
      transition: all ease-in .2s;
      position: relative;
      padding: 0;
      &.active {
        color: black;
        &:after {
          content: '';
          width: 1.75vw;
          height: 1.75vw;
          position: absolute;
          right: -3vw;
          top: .6vw;
          background-color: black;
        }
      }
      &:hover {
        color: black;
      }
    }
    .pageGroup {
      display: flex;
      flex-direction: column;
      .subOptions {
        display: flex;
        flex-direction: column;
        transition: max-height .5s ease-in-out;
        max-height: 0;
        overflow: hidden;
        &.show {
          max-height: 500px;
          overflow: visible;
        }
        .subOption {
          text-align: right;
          font-family: myriad-pro;
          font-weight: 900;
          color: white;
          font-size: 2vw;
          line-height: 1.5;
          text-transform: uppercase;
          cursor: pointer;
          transition: color ease-in .2s, opacity .5s ease-in-out;
          position: relative;
          opacity: 0;
          padding: 0;
          &.show {
            opacity: 1;
          }
          &.active {
            color: black;
            &:after {
              content: '';
              width: 1.75vw;
              height: 1.75vw;
              position: absolute;
              right: -3vw;
              top: .5vw;
              background-color: black;
            }
          }
          &:hover {
            color: black;
          }
        }
      }
    }
  }
  .middle {
    padding-top: 9vw;
    border-left: 1px solid rgba(151, 151, 151, .7);
    transform: translateY(100%);
    transition: none;
    &.show {
      transform: translateY(0%);
      transition: all .3s .2s;
    }
    .item {
      padding: .7vw 2vw .7vw 2vw;
      font-family: myriad-pro;
      font-weight: 900;
      color: white;
      font-size: 1.1vw;
      text-transform: uppercase;
      transition: all ease-in .2s;
      cursor: pointer;
      width: 100%;
      text-align: left;
      display: block;
      position: relative;
      &:hover {
        color: black;
        .highlight {
          opacity: 1;
        }
      }
      .highlight {
        opacity: 0;
        height: 1.5vw;
        width: 1.5vw;
        position: absolute;
        right: 1.5vw;
        top: .5vw;
        transition: all .25s ease-in-out;
      }
    }
    .item:not(:last-of-type) {
      border-bottom: 1px solid rgba(151, 151, 151, .7);
    }
  }
  .right {
    height: 100%;
    width: 100%;
    padding: 9vw 0 4vw 2vw;
    transform: translateX(100%);
    transition: none;
    &.show {
      transform: translateX(0%);
      transition: all .3s .2s;
    }
    .image {
      height: 100%;
      object-fit: cover;
      position: absolute;
      right: 0;
      width: 100%;
      top: 0;
    }
    .overlay {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0,0,0,0.1);
    }
    .text {
      position: relative;
      font-family: myriad-pro;
      font-weight: 900;
      text-transform: uppercase;
      color: white;
      font-size: 4vw;
      line-height: 4.5vw;
    }
  }
  @media (max-width: 500px) {
    display: none;
  }
}