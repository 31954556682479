.checkboxField {
  margin: 0;
  width: 100%;
  padding-right: 2vw;
  cursor: pointer;
  position: relative;
  display: flex;
  label {
    font-family: league-gothic;
    color: black;
    text-transform: uppercase;
    font-size: 1vw;
    line-height: 1.5;
    cursor: pointer;
    display: flex;
    padding-left: 2vw;
    padding-top: .2vw;
    .box {
      border: 1px solid #0056b8;
      width: 1.2vw;
      height: 1.2vw;
      margin-top: .25vw;
      position: absolute;
      top: 0;
      left: 0;
      &:before {
        content: '';
        position: absolute;
        top: .1vw;
        left: .3vw;
        height: .8vw;
        width: .4vw;
        transform: rotate(40deg);
        border-right: .25vw solid white;
        border-bottom: .25vw solid white;
        opacity: 0;
        transition: opacity .25s ease-in-out;
      }
      &.active {
        opacity: 1;
        background-color: #0056b8;
        &:before {
          opacity: 1;
        }
      }
    }
  }
  input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
  }
  @media (max-width: 500px) {
    label {
      font-size: 4vw;
      padding-left: 8vw;
      padding-top: 1.2vw;
      .box {
        width: 4.8vw;
        height: 4.8vw;
        margin-right: 8vw;
        margin-top: 1vw;
        top: .6vw;
        left: 0;
        &:before {
          top: .60vw;
          left: 1.6vw;
          height: 3.2vw;
          width: 1.2vw;
          border-right: .5vw solid white;
          border-bottom: .5vw solid white;
        }
      }
    }
  }
}