.sessionBreakdown {
  width: 100%;
  padding: 5vw 0% 2vw 0%;
  min-width: 67vw;
  .heading {
    font-family: league-gothic;
    color: black;
    text-transform: uppercase;
    font-size: 1.7vw;
    line-height: 1.5;
    margin-left: 5%;
  }
  .body {
    font-family: myriad-pro;
    font-weight: 900;
    color: #6D6D6D;
    font-size: 1.3vw;
    line-height: 1.3;
    margin-right: 30%;
    margin-bottom: 7vw;
    margin-left: 5%;

  }
  .sessionHeadings, .mobileSessionHeadings {
    font-family: myriad-pro;
    font-weight: 900;
    color: black;
    text-transform: uppercase;
    font-size: 1.9vw;
    display: flex;
    padding-left: 25%;
    width: 100%;
    span {
      margin-right: 11vw;
    }
  }
  .mobileSessionHeadings {
    display: none;
  }
  .programs, .programsMobile {
    padding-top: 4vw;
    position: relative;
    width: 100%;
    .program {
      position: relative;
      width: 100%;
      margin-bottom: 1vw;
      height: 3vw;
      margin-left: 25%;
      .programTitle {
        font-family: myriad-pro;
        font-weight: 900;
        color: black;
        font-size: 1.7vw;
        position: absolute;
        left: -20%;
        line-height: 1.5;
      }
      .morning, .afternoon {
        position: absolute;
        background-color: #0056B8;
        border-radius: 2vw;
        height: 2.5vw;
        top: 0;
        display: flex;
        align-items: center;
        padding: 0 1vw 0 1vw;
        justify-content: space-between;
        transition: all .5s ease-out;
        .time {
          color: white;
          font-family: myriad-pro;
          font-weight: 900;
          font-size: 1.2vw;
          opacity: 0;
          transition: all .5s ease-out .5s;
          &.show {
            opacity: 1;
          }
        }
      }
    }
    .timeColumns {
      top: 1vw;
      position: absolute;
      width: 100%;
      padding-left: 25%;
      padding-right: 17%;
      display: grid;
      grid-template-columns: 25% 25% 25% 25% 25%;
      height: 100%;
      div {
        position: relative;
        border-left: 1px solid #D8D8D8;
        p {
          position: absolute;
          top: 0;
          left: 1vw;
          font-family: myriad-pro;
          font-weight: 900;
          color: #D8D8D8;
          font-size: 1.7vw;
        }
      }
    }
  }
  .programsMobile {
    display: none;
  }
  .ctaContainer {
    display: flex;
    justify-content: left;
    position: relative;
    width: 100%;
    margin-top: 5vw;
    margin-bottom: 5vw;
    margin-left: 5%;
    padding-right: 10%;
    .cta {
      position: relative;
      cursor: pointer;
      padding: 0;
      .buttonLabel {
        font-family: league-gothic;
        text-transform: uppercase;
        font-size: 1.9vw;
        transition: all ease-in .2s;
      }
      .arrow {
        position: absolute;
        right: -5vw;
        top: -3.1vw;
        height: 7vw;
        width: 2.8vw;
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDpyZ2IoMCwwLDApOyIvPgogICAgPC9nPgo8L3N2Zz4K");
        background-position: center left;
        transform: rotate(-90deg);
        background-repeat: no-repeat no-repeat;
        z-index: 2;
        background-size: contain;
      }
      &:hover {
        .buttonLabel {
          color: rgba(0, 86, 184, 1);
        }
        .arrow {
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDojMDQ2NmIyOyIvPgogICAgPC9nPgo8L3N2Zz4K");
        }
      }
    }
  }
  @media (max-width: 500px) {
    .heading {
      font-size: 5vw;
    }
    .body {
      font-size: 4vw;
    }
    .sessionHeadings {
      display: none;
    }
    .mobileSessionHeadings {
      display: block;
      font-size: 4vw;
      padding-left: 5%;
      margin-bottom: 5vw;
      position: relative;
    }
    .programs {
      display: none;
    }
    .programsMobile {
      display: block;
      margin-bottom: 10vw;
      .program {
        margin-left: 10%;
        height: 18vw;
        z-index: 1;
        .programTitle {
          font-size: 4vw;
          left: -5%;
          top: 35%;
        }
        .morning, .afternoon {
          height: 5vw;
          top: 11%;
          .time {
            font-size: 3.5vw;
          }
        }
      }
      .timeColumns {
        padding-left: 10%;
        grid-template-columns: 40% 40% 10%;
        z-index: 0;
        div {
          p {
            font-size: 3.5vw;
          }
        }
      }
    }
    .ctaContainer {
      margin-top: 15vw;
      width: 100%;
      .cta {
        .buttonLabel {
          font-size: 5vw;
        }
        .arrow {
          right: -13vw;
          top: -7vw;
          height: 21vw;
        }
      }
    }
  }
}