.instructor {
  width: 100%;
  position: relative;
  display: flex;
  .left {
    width: 50%;
    height: 100%;
    padding: 5% 7%;
    .name {
      color: black;
      font-family: league-gothic;
      text-transform: uppercase;
      font-size: 1.8vw;
      line-height: 1.5;
      margin-bottom: 2vw;
      opacity: 0;
      transform: translateX(-100px);
      transition: all .7s ease-in-out;
    }
    .description, .email, .phone {
      color: #6D6D6D;
      font-family: myriad-pro;
      font-size: 1.3vw;
      padding-left: 2vw;
      line-height: 1.5;
      opacity: 0;
      transform: translateX(-100px);
      transition: all .7s ease-in-out;
    }
    .description {
      margin-bottom: 3vw;
    }
    .ctaContainer {
      display: flex;
      justify-content: left;
      position: relative;
      width: 100%;
      margin-top: 4vw;
      margin-bottom: 4vw;
      padding-right: 10%;
      padding-left: 2vw;
      opacity: 0;
      transform: translateX(-100px);
      transition: all .7s ease-in-out;
      .cta {
        position: relative;
        cursor: pointer;
        padding: 0;
        .buttonLabel {
          font-family: league-gothic;
          font-size: 1.9vw;
          text-transform: uppercase;
          transition: all ease-in .2s;
          color: black;
        }
        .arrow {
          position: absolute;
          right: -5vw;
          top: -3.5vw;
          height: 8vw;
          width: 2vw;
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDojMDkxQzNEOyIvPgogICAgPC9nPgo8L3N2Zz4K");
          background-position: center left;
          transform: rotate(-90deg);
          background-repeat: no-repeat no-repeat;
          z-index: 2;
          background-size: contain;
        }
        &:hover {
          .buttonLabel {
            color: rgba(0, 86, 184, 1);
          }
          .arrow {
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDojMDQ2NmIyOyIvPgogICAgPC9nPgo8L3N2Zz4K");
          }
        }
      }
    }
  }
  .right {
    height: 52vw;
    width: 50%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &.show {
    .left {
      .name, .description, .phone, .email, .ctaContainer {
        transform: translate(0,0);
        opacity: 1;
      }
    }
  }
  @media (max-width: 500px) {
    display: block;
    .left {
      width: 100%;
      padding: 9% 7%;
      .name {
        font-size: 5vw;
      }
      .description, .email, .phone {
        font-size: 4vw;
      }
      .ctaContainer {
        justify-content: left;
        padding-left: 2vw;
        margin-top: 10vw;
        .cta {
          .buttonLabel {
            font-size: 6vw;
          }
          .arrow {
            right: -13vw;
            top: -7vw;
            height: 22vw;
          }
        }
      }
    }
    .right {
      width: 100%;
      height: 75vw;
    }
  }
}