.checkboxListField {
  margin: 0;
  width: 100%;
  padding-right: 2vw;
  position: relative;
  fieldset {
    legend {
      font-family: league-gothic;
      color: #9E9E9E;
      font-size: 1.3vw;
      font-weight: 500;
      margin-bottom: 1vw;
      padding: .2vw 0 0 0;
      line-height: 1.5;
    }
    .checkboxField {
      margin: 0;
      width: 100%;
      padding-right: 2vw;
      cursor: pointer;
      position: relative;
      label {
        font-family: league-gothic;
        font-weight: 500;
        font-size: 1.4vw;
        color: black;
        line-height: 1.5;
        cursor: pointer;
        display: flex;
        padding-left: 2vw;
        padding-top: .2vw;
        position: relative;
        .box {
          background-color: #D8D8D8;
          width: 1.2vw;
          height: 1.2vw;
          margin-right: 2vw;
          margin-top: .25vw;
          position: absolute;
          top: .3vw;
          left: 0;
          &:before {
            content: '';
            position: absolute;
            top: .15vw;
            left: .4vw;
            height: .8vw;
            width: .4vw;
            transform: rotate(40deg);
            border-right: .25vw solid black;
            border-bottom: .25vw solid black;
            opacity: 0;
            transition: opacity .25s ease-in-out;
          }
          &.active {
            opacity: 1;
            &:before {
              opacity: 1;
            }
          }
        }
      }
      input[type="checkbox"] {
        opacity: 0;
        width: 0;
        height: 0;
        position: absolute;
      }
    }
  }
  @media (max-width: 500px) {
    fieldset {
      legend {
        font-size: 4vw;
      }
      .checkboxField {
        label {
          font-size: 4vw;
          padding-left: 8vw;
          padding-top: 1.2vw;
          .box {
            background-color: #D8D8D8;
            width: 4.8vw;
            height: 4.8vw;
            margin-right: 8vw;
            margin-top: 1vw;
            position: absolute;
            top: .6vw;
            left: 0;
            &:before {
              content: '';
              position: absolute;
              top: .60vw;
              left: 1.6vw;
              height: 3.2vw;
              width: 1.2vw;
              transform: rotate(40deg);
              border-right: .75vw solid black;
              border-bottom: .75vw solid black;
              opacity: 0;
              transition: opacity .25s ease-in-out;
            }
            &.active {
              opacity: 1;
              &:before {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}