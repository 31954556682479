.header {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  .topNav {
    position: relative;
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-end;
    align-items: center;
    background-color: #D8D8D8;
    padding: .4vw 1vw;
    z-index: 1;
  }
  .mainNav {
    background-color: white;
    padding: 0.5vw 2vw 0.5vw 2vw;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;
    align-items: center;
    height: 7vw;
    box-shadow: 0 0 10px 2px rgba(0,0,0,.1);
    z-index: 1;
    position: relative;
    .nav {
      display: grid;
      grid-auto-flow: column;
      gap: 3vw;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .subNav {
    background-color: #0466b2;
    width: 100%;
    padding: .4vw 10% .6vw 25%;
  }
  @media (max-width: 500px) {
    .skipContainer {
      padding: 1.5vw 3vw;
      .skip {
        font-size: 3vw;
      }
    }
    .topNav {
      display: none;
    }
    .mainNav {
      height: initial;
    }
    .links {
      grid-template-rows: 1fr;
      align-items: center;
      .topNav {
        display: none;
      }
    }
  }
}
