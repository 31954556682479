.cta {
  background-color: #D8D8D8;
  padding: 8vw 10vw 5vw;
  display: grid;
  grid-template-rows: auto 1fr auto auto;
  align-items: center;
  height: calc(100vh - 7vw);
  width: 100%;
  max-height: 56vw;
  position: relative;
  overflow: hidden;
  position: relative;
  .backgroundImage {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.1);
  }
  .heading {
    font-family: league-gothic;
    color: white;
    padding-right: 30%;
    font-size: 3.7vw;
    line-height: 1.1;
    margin-bottom: 3vw;
    text-transform: uppercase;
    z-index: 2;
    transition: all .7s ease-out;
    transform: translateY(100px);
    opacity: 0;
    &.big {
      font-size: 5.5vw;
    }
  }
  .body {
    color: white;
    font-family: league-gothic;
    font-size: 2vw;
    padding-right: 16%;
    margin-bottom: 9vw;
    align-self: center;
    z-index: 2;
    text-transform: uppercase;
    transition: all .7s ease-out .2s;
    transform: translateY(100px);
    opacity: 0;
  }
  .button {
    border: unset;
    border-radius: unset;
    background: unset;
    padding-left: 0;
    padding-right: 0;
    z-index: 2;
    justify-self: flex-start;
    cursor: pointer;
    margin-bottom: 3vw;
    transition: all .7s ease-out .4s;
    transform: translateY(100px);
    opacity: 0;
    .buttonLabel {
      color: white;
      font-family: league-gothic;
      font-size: 1.8vw;
      line-height: 1.75;
      margin-bottom: 1vw;
      text-transform: uppercase;
      transition: all 0.25s ease-in-out;
      &:hover {
        color: #0466b2;
      }
    }
    .arrow {
      height: 9vw;
      width: 1vw;
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDpyZ2IoMjU1LDI1NSwyNTUpOyIvPgogICAgPC9nPgo8L3N2Zz4K");
      background-position: center left;
      background-repeat: no-repeat no-repeat;
      z-index: 2;
      background-size: contain;
    }
    &:hover {
      .buttonLabel {
        color: #0466b2;
      }
      .arrow {
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDojMDQ2NmIyOyIvPgogICAgPC9nPgo8L3N2Zz4K");
      }
    }
  }
  .changer {
    position: absolute;
    bottom: 1vw;
    left: 0;
    padding: 2vw 0 1vw 8vw;
    .changerHeading {
      font-family: league-gothic;
      text-transform: uppercase;
      color: white;
      font-size: 2vw;
      margin: 0 0 1vw 0;
      padding: 0;
    }
    .pageLinks {
      display: flex;
      .changerPage {
        font-family: myriad-pro;
        font-weight: 900;
        text-transform: uppercase;
        color: white;
        font-size: 1.2vw;
        margin-right: 6vw;
        position: relative;
        transition: all .25s ease-in-out;
        &.active {
          color: black;
          &:before {
            content: "";
            width: .6vw;
            height: .6vw;
            position: absolute;
            left: -1vw;
            top: .5vw;
            background-color: black;
          }
        }
        &:hover {
          color: black
        }
      }
    }
  }
  .slantedBorder {
    position: absolute;
    left: -7.5vw;
    width: 115%;
    height: 15vw;
  }
  .largeText {
    bottom: 5vw;
    font-family: league-gothic;
    font-size: 22vw;
    line-height: 1.17;
    text-transform: uppercase;
    color: white;
    opacity: .4;
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0;
    position: absolute;
    white-space: nowrap;
  }
  
  @media (max-width: 500px) {
    padding: 20vw 10vw 5vw;
    max-height: unset;
    height: calc(100vh - 23vw);
    .heading {
      padding-right: 0;
      font-size: 11vw;
      letter-spacing: -0.4vw;
      line-height: 1.25;
      margin-bottom: 3vw;
      &.big {
        font-size: 14vw;
      }
    }
    .body {
      font-size: 6.5vw;
      line-height: 1.2;
      letter-spacing: -0.1vw;
      padding-right: 0;
      margin-bottom: 20vw;
    }
    .button {
      .buttonLabel {
        font-size: 7vw;
        line-height: 1.2;
        margin-bottom: 3vw;
        letter-spacing: -0.4vw;
      }
      .arrow {
        height: 18vw;
        width: 2.4vw;
      }
    }
    .slantedBorder {
      height: 25vw;
    }
    .changer {
      bottom: 3vw;
      .changerHeading {
        font-size: 5vw;
        margin: 0 0 4vw 0;
      }
      .pageLinks {
        flex-direction: column;
        .changerPage {
          font-size: 4vw;
          display: block;

          &.active {
            &:before {
              width: 2vw;
              height: 2vw;
              left: -3vw;
              top: 2vw;
            }
          }
        }
      }
    }
    .slantedBorder {
      height: 42vw;
    }
    .largeText {
      font-size: 26vw;
      bottom: 32vw;
    }
  }
  &.show {
    .heading, .body, .button {
      opacity: 1;
      transform: translateY(0);
    }
  }
}