.logo {
  height: 100%;
  width: 14vw;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: 68%;
  position: absolute;
  left: 1vw;
  top: 0;
  @media (max-width: 500px) {
    display: none;
  }
}
