.map {
  width: 100%;
  padding: 2% 0 0;
  display: flex;
  justify-content: center;
  .hiddenTitle {
    margin: 0;
    padding: 0;
    font-size: 0px;
  }
  .image {
    width: 55%;
    object-fit: contain;
  }
  @media (max-width: 500px) {
    .image {
      width: 96%;
    }
  }
}