.callButton {
  display: flex;
  align-items: center;
  font-family: myriad-pro;
  font-weight: 900;
  font-size: 1vw;
  transition: all 0.25s ease-in-out;
  padding: 0.25vw 1.5vw .3vw 1.5vw;
  &:hover {
    color: black;
  }
  img {
    width: 2vw;
    height: 2.1vw;
  }
  @media (max-width: 500px) {
    display: none;
  }
}