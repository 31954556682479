.tabsTwoColumns {
  width: 100%;
  padding: 5% 9%;
  display: flex;
  background-color: #0056b8;
  min-height: 35vw;
  .left {
    width: 52%;
    padding-right: 5%;
    transition: all 0.5s ease-in-out;
    transform: translateX(-100px);
    opacity: 0;
    z-index: 4;
    .sticky {
      position: sticky;
      top: 20vw;
      .tab {
        text-align: left;
        display: block;
        font-family: myriad-pro;
        text-transform: uppercase;
        font-weight: 900;
        color: black;
        font-size: 1.5vw;
        line-height: 1.7;
        position: relative;
        transition: all 0.25s ease-in-out;
        margin-left: 1.5vw;
        &.active {
          color: white;
          &:before {
            content: "";
            height: 1vw;
            width: 1vw;
            background-color: white;
            position: absolute;
            left: -2vw;
            top: 0.7vw;
          }
        }
        &:hover {
          color: white;
        }
      }
    }
  }
  .right {
    width: 48%;
    transition: all 0.5s ease-in-out;
    transform: translateX(100px);
    opacity: 0;
    position: relative;
    z-index: 1;
    .rightText {
      font-family: myriad-pro;
      line-height: 1.5;
      color: white;
      font-size: 1.3vw;
      transition: all 0.25s ease-in-out;
      opacity: 1;
      &.hide {
        opacity: 0;
        transition: none;
      }
      strong {
        color: white;
        font-size: 1.6vw;
        font-family: myriad-pro;
        font-weight: 900;
      }
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .ctaContainer {
      display: flex;
      justify-content: left;
      position: relative;
      width: 100%;
      margin-top: 5vw;
      margin-bottom: 5vw;
      padding-right: 10%;
      .cta {
        position: relative;
        cursor: pointer;
        padding: 0;
        .buttonLabel {
          font-family: league-gothic;
          font-size: 1.4vw;
          transition: all ease-in 0.2s;
          text-transform: uppercase;
          color: white;
        }
        .arrow {
          position: absolute;
          right: -5vw;
          top: -3.8vw;
          height: 8vw;
          width: 2vw;
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDpyZ2IoMCwwLDApOyIvPgogICAgPC9nPgo8L3N2Zz4K");
          background-position: center left;
          transform: rotate(-90deg);
          background-repeat: no-repeat no-repeat;
          z-index: 2;
          background-size: contain;
        }
        &:hover {
          .buttonLabel {
            color: black;
          }
        }
      }
    }
  }
  .cubeOne {
    position: absolute;
    top: 0;
    left: -10vw;
    height: 20vw;
    width: 20vw;
    z-index: 0;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NTkiIGhlaWdodD0iNDU3Ij48cGF0aCBkPSJNIDAgMCBMIDM0OSAwIEwgMzQ5IDEwOCBMIDExMCAxMDggTCAxMTAgMzQ5IEwgMCAzNDkgWiBNIDM0OSAzNDkgTCAxMTAgMzQ5IEwgMTEwIDQ1NyBMIDQ1OSA0NTcgTCA0NTkgMTA4IEwgMzQ5IDEwOCBaIiBmaWxsPSJyZ2IoMjU1LCAyNTUsIDI1NSkiIG9wYWNpdHk9IjAuNTU0ODI0MTAyNDkyNTU5NSI+PC9wYXRoPjwvc3ZnPg==");
    background-repeat: no-repeat;
    background-size: contain;
    display: none;
    &.light {
      display: block;
    }
  }
  .cubeTwo {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 20vw;
    width: 20vw;
    z-index: 0;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NTkiIGhlaWdodD0iNDU3Ij48cGF0aCBkPSJNIDAgMCBMIDM0OSAwIEwgMzQ5IDEwOCBMIDExMCAxMDggTCAxMTAgMzQ5IEwgMCAzNDkgWiBNIDM0OSAzNDkgTCAxMTAgMzQ5IEwgMTEwIDQ1NyBMIDQ1OSA0NTcgTCA0NTkgMTA4IEwgMzQ5IDEwOCBaIiBmaWxsPSJyZ2IoMjU1LCAyNTUsIDI1NSkiIG9wYWNpdHk9IjAuNTU0ODI0MTAyNDkyNTU5NSI+PC9wYXRoPjwvc3ZnPg==");
    background-repeat: no-repeat;
    background-size: contain;
    display: none;
    &.light {
      display: block;
    }
  }
  &.show {
    .left,
    .right {
      opacity: 1;
      transform: translateX(0);
    }
  }
  &.light {
    background-color: #e0e8f5;
    .left {
      .tab {
        &.active {
          color: #0056b8;
          &:before {
            background-color: #0056b8;
          }
        }
        &:hover {
          color: #0056b8;
        }
      }
    }
    .right {
      .rightText {
        color: #6d6d6d;
        strong {
          color: #0056b8;
        }
      }
      .ctaContainer {
        .cta {
          .buttonLabel {
            color: #0056b8;
          }
          &:hover {
            .buttonLabel {
              color: black;
            }
          }
        }
      }
    }
  }
  @media (max-width: 500px) {
    display: block;
    .left {
      width: 100%;
      .sticky {
        position: relative;
        top: initial;
        .tab {
          font-size: 5vw;
          margin-bottom: 0vw;
          &.active {
            &:before {
              height: 3vw;
              width: 3vw;
              left: -5vw;
              top: 2.5vw;
            }
          }
        }
      }
    }
    .right {
      width: 100%;
      .rightText {
        font-size: 4vw;
        margin-top: 5vw;
        strong {
          font-size: 4.6vw;
        }
      }
      .ctaContainer {
        margin-top: 10vw;
        margin-bottom: 10%;
        .cta {
          .buttonLabel {
            font-size: 6vw;
            max-width: 67vw;
          }
          .arrow {
            right: -13vw;
            top: -6.5vw;
            height: 22vw;
          }
          &:hover {
            .buttonLabel {
              color: black;
            }
          }
        }
      }
    }
    .cubeOne,
    .cubeTwo {
      height: 40vw;
      width: 40vw;
      z-index: 0;
    }
  }
}
