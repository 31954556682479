.select {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 2vw;
  position: relative;
  select {
    background-color: transparent;
    border: none;
    border-bottom: .25vw solid black;
    appearance: none;
    font-family: league-gothic;
    font-weight: 500;
    font-size: 1.4vw;
    line-height: 1.5;
    padding: 2.3vw 0 .5vw 1vw;
    cursor: pointer;
  }
  label {
    font-family: league-gothic;
    color: #9E9E9E;
    font-size: 1.3vw;
    font-weight: 500;
    line-height: 1.5;
    position: absolute;
    top: 0;
    left: 0;
  }
  .chevron {
    position: absolute;
    transform: rotate(45deg);
    border-right: .3vw solid black;
    border-bottom: .3vw solid black;
    width: 1vw;
    height: 1vw;
    right: 3vw;
    top: 2.75vw;
    transition: .7s ease-out;
    cursor: pointer;
    z-index: -1;
  }
  @media (max-width: 500px) {
    select {
      font-size: 4vw;
      padding: 6.4vw 0 1vw 2vw;
    }
    label {
      font-size: 4vw;
      position: relative;
    }
    .chevron {
      position: absolute;
      transform: rotate(45deg);
      border-right: .6vw solid black;
      border-bottom: .6vw solid black;
      width: 3vw;
      height: 3vw;
      right: 3vw;
      top: unset;
      bottom: 3vw;
      transition: .7s ease-out;
      cursor: pointer;
      z-index: -1;
    }
  }
}