.siteSearch {
  position: absolute;
  left: 35vw;
  width: 30vw;
  height: 100%;
  display: grid;
  padding: .39vw 0;
  .searchBar {
    border: none;
    border-radius: 2vw;
    font-family: myriad-pro;
    font-size: 1.4vw;
    padding: 0 11vw 0 3vw;
    width: 100%;
    background-size: 1vw;
    background-image: url("data:image/svg+xml;base64, PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogc2tldGNodG9vbCA1MS4yICg1NzUxOSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+ODc3MTIyQkEtRTBDNy00QjVELUFBOEQtNUZBOTg0MDc5RDhFPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBza2V0Y2h0b29sLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgPGcgaWQ9ImZlYXRoZXItaWNvbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTk5Ni4wMDAwMDAsIC02MDguMDAwMDAwKSIgc3Ryb2tlPSIjMDQ2NmIyIiBzdHJva2Utd2lkdGg9IjIiPgogICAgICAgICAgICA8ZyBpZD0ic2VhcmNoIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg5OTcuMDAwMDAwLCA2MDkuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8Y2lyY2xlIGlkPSJPdmFsIiBjeD0iNy41IiBjeT0iNy41IiByPSI3LjUiPjwvY2lyY2xlPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTE4LDE4IEwxMi44LDEyLjgiIGlkPSJTaGFwZSI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=");
    background-repeat: no-repeat;
    background-position: 5%;
  }
  .overlay {
    display: none;
  }
  .results {
    background-color: white;
    position: fixed;
    height: 93vh;
    top: 9vw;
    left: 0;
    width: 100vw;
    overflow: scroll;
    display: none;
    padding: 2vw 2vw 4vw;
    .overlay {
      display: none;
    }
    .close {
      position: fixed;
      top: 9.5vw;
      right: 3vw;
      color: black;
      font-family: myriad-pro;
      font-weight: 900;
      font-size: 2.1vw;
      z-index: 2;
    }
    .result {
      margin: .5vw;
      padding: .5vw;
      &:hover {
        background: #e4e4e4;
      }
      a {
        h5 {
          font-family: myriad-pro;
          font-size: 1.4vw;
          margin: 0;
          margin-bottom: 1rem;
          line-height: 1.5;
        }
        p {
          font-family: myriad-pro;
        font-size: 1.5vw;
        display: block;
        margin: 1vw;
        line-height: 1.3;
        }
      }
    }
    &.show {
      display: block;
    }
  }
  @media (max-width: 500px) {
    left: 5vw;
    width: 90vw;
    top: 0;
    z-index: 20;
    position: relative;
    .searchBar {
      border-radius: 4vw;
      font-size: 4vw;
      background-size: 3vw;
      padding: 1.5vw 40vw 1.5vw 10vw;
      z-index: 2;
      position: relative;
      box-shadow: 0 0 10px 2px rgba(0,0,0,.1);
    }
    
    .results {
      padding: 17vw 3vw;
      top: 0;
      .overlay {
        position: fixed;
        top: 0vw;
        left: 0;
        height: 13vw;
        width: 100vw;
        background-color: white;
        display: block;
        z-index: 19;
      }
      .close {
        top: 12vw;
        font-size: 4vw;
      }
      .result {
        margin: 1vw .5vw;
        padding: 1vw;
       
        a {
          h5 {
            font-size: 4vw;
            margin-bottom: .5rem;
          }
          p {
            font-size: 3.5vw;
          }
        }
      }
    }
  }
}
