.twoColumnText {
  width: 100%;
  padding: 3% 11% 2%;
  display: flex;
  background-color: #0056B8;
  .left {
    width: 50%;
    padding-right: 5%;
    transition: all .5s ease-in-out;
    transform: translateX(-100px);
    opacity: 0;
    .leftText {
      font-family: myriad-pro;
      font-weight: 900;
      color: black;
      font-size: 2.25vw;
      line-height: 1.5;
      a {
        font-weight: 600;
      }
    }
  }
  .right {
    width: 50%;
    transition: all .5s ease-in-out;
    transform: translateX(100px);
    opacity: 0;
    .rightText {
      font-family: myriad-pro;
      font-weight: 900;
      line-height: 1.5;
      color: white;
      font-size: 1.3vw;
      a {
        font-weight: 600;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .ctaContainer {
      display: flex;
      justify-content: left;
      position: relative;
      width: 100%;
      margin-top: 5vw;
      margin-bottom: 5vw;
      padding-right: 10%;
      .cta {
        position: relative;
        cursor: pointer;
        padding: 0;
        .buttonLabel {
          font-family: league-gothic;
          font-size: 1.7vw;
          transition: all ease-in .2s;
          color: black;
          text-transform: uppercase;
        }
        .arrow {
          position: absolute;
          right: -5vw;
          top: -3.5vw;
          height: 8vw;
          width: 2vw;
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDpyZ2IoMCwwLDApOyIvPgogICAgPC9nPgo8L3N2Zz4K");
          background-position: center left;
          transform: rotate(-90deg);
          background-repeat: no-repeat no-repeat;
          z-index: 2;
          background-size: contain;
        }
        &:hover {
          .buttonLabel {
            color: white;
          }
        }
      }
    }
  }
  &.show {
    .left, .right {
      opacity: 1;
      transform: translateX(0);
    }
  }
  &.light {
    background-color: white;
    .left {
      .leftText {
        color: #0056B8;
      }
    }
    .right {
      .rightText {
        color: #6D6D6D;
      }
      .ctaContainer {
        .cta {
          .buttonLabel {
            color: #0056B8;
          }
        }
      }
    }
  }
  @media (max-width: 500px) {
    display: block;
    .left {
      width: 100%;
      .leftText {
        font-size: 7vw;
        margin-bottom: 5vw;
      }
    }
    .right {
      width: 100%;
      .rightText {
        font-size: 4vw;
      }
      .ctaContainer {
        margin-top: 10vw;
        margin-bottom: 10%;
        .cta {
          text-align: left;
          .buttonLabel {
            font-size: 6vw;
            max-width: 67vw;
          }
          .arrow {
            right: -13vw;
            top: -7.5vw;
            height: 22vw;
          }
        }
      }
    }
  }
}