body {
  margin: 0;
  font-family: myriad-pro;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flow-root;
  overflow: clip;
}

@media (max-width: 767px) {
  body {
    overflow-x: hidden;
  }
}

code {
  font-family: myriad-pro;
  /* Test */
}
