.menu {
  display: none;
  @media (max-width: 500px) {
    display: block;
    position: absolute;
    top: 23vw;
    left: 0;
    background-color: #0056B8;
    width: 100vw;
    height: calc(100vh - 23vw);
    padding: 13vw 5vw 5vw 5vw;
    z-index: 100;
    transform: translateX(105%);
    transition: all .25s ease-in-out;
    &.open {
      transform: translateX(0);
    }
    .top {
      position: fixed;
      top: -1vw;
      left: 0;
      display: flex;
      padding-top: 3vw;
      align-items: center;
    }
    .links {
      overflow: scroll;
      height: 100%;
      width: 100%;
      .link, .topLink {
        display: block;
        font-family: league-gothic;
        color: white;
        font-size: 9vw;
        line-height: 1.5;
        text-transform: uppercase;
        cursor: pointer;
        transition: all ease-in .2s;
        position: relative;
        padding: 0;
        padding-left: 5vw;
        margin-bottom: 6vw;
        &.active {
          color: black;
          &:after {
            content: '';
            width: 4vw;
            height: 4vw;
            position: absolute;
            left: -1vw;
            top: 3.8vw;
            background-color: black;
          }
        }
      }
    }
  
  }
}
