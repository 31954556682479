.textMultipleLinks {
  position: relative;
  width: 100%;
  padding: 3vw 10vw;
  overflow: hidden;
  .heading {
    font-family: league-gothic;
    text-transform: uppercase;
    font-size: 2.1vw;
    color: black;
    line-height: 1.4;
    margin-bottom: 4.5vw;
    transition: all .7s ease-out;
    opacity: 0;
    transform: translateX(-100px);
  }
  .subheading {
    font-family: myriad-pro;
    font-size: 1.4vw;
    line-height: 1.4;
    transition: all .7s ease-out;
    color: #6D6D6D;
    opacity: 0;
    transform: translateX(-100px);
    margin-bottom: 3vw;
  }
  .links {
    display: flex;
    flex-direction: column;
    width: 100%;
    .link {
      display: flex;
      align-items: center;
      width: 50%;
      margin-bottom: 2vw;
      transition: all .7s ease-out;
      opacity: 0;
      transform: translateX(-100px);
      &:hover {
        a {
          img {
            transition: all .25s ease-in-out;
            transform: scale(1.1);
          }
        }
      }
      a {
        width: 100%;
        display: flex;
        align-items: center;
        img {
          width: 4vw;
          height: 4vw;
          margin-right: 3vw;
          margin-bottom: 0;
          object-fit: contain;
        }
        span {
          font-family: myriad-pro;
          text-transform: uppercase;
          font-weight: 900;
          font-size: 1.3vw;
          line-height: 1.4;
          color: #6D6D6D;
        }
      }
    }
  }
  .ctaContainer {
    width: 35%;
    transition: all .7s ease-out 1s;
    opacity: 0;
    transform: translateX(-100px);
    .cta {
      position: relative;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      p {
        color: black;
        font-family: league-gothic;
        transition: all 0.25s ease-in-out;
        font-size: 1.3vw;
        line-height: 1.5;
        text-transform: uppercase;
      }
      .arrowContainer {
        height: 8vw;
        overflow: hidden;
        .arrow {
          height: 8vw;
          width: 1vw;
          transition: all 0.25s ease-in-out;
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDojMDQ2NmIyOyIvPgogICAgPC9nPgo8L3N2Zz4K");
          background-position: center left;
          background-repeat: no-repeat no-repeat;
          z-index: 2;
          background-size: contain;
          transform: translateY(-25%);
        }
      }
      
      &:hover {
        p {
          color: black;
        }
        .arrow {
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDojMDkxQzNEOyIvPgogICAgPC9nPgo8L3N2Zz4K");
        }
      }
    }
  }
  &.show {
    .heading, .subheading, .ctaContainer {
      opacity: 1;
      transform: translateX(0);
    }
    .links {
      .link {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  @media (max-width: 500px) {
    padding: 10% 8% 10% 8%;
    .heading, .subheading {
      margin-bottom: 5vw;
    }
    .heading {
      font-size: 6vw;
    }
    .subheading {
      font-size: 4.2vw;
      margin-bottom: 5vw;
    }
    .links {
      flex-wrap: unset;
      flex-direction: column;
      .link {
        width: 100%;
        margin-bottom: 5vw;
        color: black;
        a {
          img {
            width: 15vw;
            height: 15vw;
          }
          span {
            font-size: 4vw;
          }
        }
      }
    }
    .ctaContainer {
      margin-top: 8vw;
      width: 100%;
      .cta {
        p {
          font-size: 4vw;
        }
        .arrowContainer {
          height: 25vw;
          .arrow {
            right: -13vw;
            top: -7.5vw;
            height: 25vw;
          }
        } 
      }
    }
   
  }
}