.main {
  width: 100%;
  padding-top: 7vw;
  p {
    margin-bottom: 0;
  }
  .development {
    padding: 30vw 10vw;
    .message {
      font-size: 3.8vw;
      letter-spacing: -0.2vw;
      line-height: 5.2vw;
      font-family: "Arial Black", "Arial";
      font-weight: 900;
      color: #d8d8d8;
    }
  }
  @media (max-width: 500px) {
    padding-top: 23vw;
    .development {
      padding: 40vw 10vw;
      .message {
        font-size: 8vw;
        letter-spacing: -0.4vw;
        line-height: 10vw;
      }
    }
  }
}
