.computerButtons {
  margin-bottom: 6vw;
  legend {
    color: black;
    font-size: 1.25vw;
    font-family: league-gothic;
    margin-bottom: 2vw;
  }
  .buttons {
    display: flex;
    flex-wrap: wrap;
    button {
      border: 1px solid #0056b8;
      color: #0056b8;
      font-size: .9vw;
      font-family: league-gothic;
      text-transform: uppercase;
      width: 33%;
      padding: .3vw;
      transition: all .25s ease-in-out;
      &:hover, &.active {
        background-color: #0056b8;
        color: white;
      }
    }
  }
 
  @media (max-width: 500px) {
    legend {
      font-size: 4vw;
    }
    .buttons {
      button {
        font-size: 3.5vw;
        padding: .6vw .3vw;
      }
    }
  }
}