.textImages {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  height: 48vw;
  .left {
    width: 50%;
    padding: 12% 5% 0 10%;
    position: relative;
    .heading {
      font-family: league-gothic;
      text-transform: uppercase;
      font-size: 1.7vw;
      line-height: 1.5;
      color: black;
    }
    .body {
      font-family: myriad-pro;
      font-size: 1.2vw;
      line-height: 1.5;
      color: #6D6D6D;
    }
    .ctaContainer {
      display: flex;
      justify-content: left;
      position: relative;
      width: 100%;
      margin-top: 10vw;
      padding-right: 10%;
      .button {
        position: relative;
        cursor: pointer;
        .buttonLabel {
          font-family: league-gothic;
          text-transform: uppercase;
          font-size: 1.5vw;
          transition: all ease-in .2s;
        }
        .arrow {
          position: absolute;
          right: -5vw;
          top: -3.5vw;
          height: 8vw;
          width: 2vw;
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDpyZ2IoMCwwLDApOyIvPgogICAgPC9nPgo8L3N2Zz4K");
          background-position: center left;
          transform: rotate(-90deg);
          background-repeat: no-repeat no-repeat;
          z-index: 2;
          background-size: contain;
        }
        &:hover {
          .buttonLabel {
            color: rgba(0, 86, 184, 1);
          }
          .arrow {
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDojMDQ2NmIyOyIvPgogICAgPC9nPgo8L3N2Zz4K");
          }
        }
      }
    }
  }
  .right {
    width: 50%;
    position: relative;
    .topPadding {
      background-color: white;
      top: 0;
      width: 100%;
      left: 0;
      height: 5%;
      position: absolute;
    }
    .topOverlay {
      position: absolute;
      top: 4.5%;
      left: 0;
      width: 100%;
      height: 30%;
      z-index: 1;
      background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    }
    .bottomPadding {
      background-color: white;
      bottom: 0;
      width: 100%;
      left: 0;
      height: 5%;
      position: absolute;
    }
    .bottomOverlay {
      position: absolute;
      bottom: 4.5%;
      left: 0;
      width: 100%;
      height: 30%;
      z-index: 1;
      background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    }
    .image0 {
      width: 25vw;
      height: 28vw;
      object-fit: cover;
      position: absolute;
      top: 5vw;
      left: 3vw;
      transition: opacity .5s, transform 2s ease-out;
      opacity: 0;
      transform: translate(0, -100px);
    }
    .image1 {
      width: 25vw;
      height: 28vw;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 31vw;
      transition: opacity .5s, transform 2s ease-out .5s;
      opacity: 0;
      transform: translate(0, -100px);
    }
    .image2 {
      width: 25vw;
      height: 20vw;
      object-fit: cover;
      position: absolute;
      top: 36vw;
      left: 3vw;
      transition: opacity .5s, transform 2s ease-out;
      opacity: 0;
      transform: translate(0, 100px);
    }
    .image3 {
      width: 25vw;
      height: 28vw;
      object-fit: cover;
      position: absolute;
      top: 31vw;
      left: 31vw;
      transition: opacity .5s, transform 2s ease-out .5;
      opacity: 0;
      transform: translate(0, 100px);
    }
  }
  &.show {
    .image0, .image1, .image2, .image3 {
      opacity: 1;
      transform: translate(0,0);
    }
  }
  @media (max-width: 500px) {
    height: unset;
    display: block;
    .left {
      width: 100%;
      background-color: white;
      z-index: 2;
      .heading {
        font-size: 6vw;
        margin-bottom: 6vw;
      }
      .body {
        font-size: 4vw;
      }
      .ctaContainer {
        margin-top: 10vw;
        margin-bottom: 10%;
        .button {
          .buttonLabel {
            font-size: 4vw;
          }
          .arrow {
            right: -13vw;
            top: -9.5vw;
            height: 22vw;
          }
        }
      }
    }
    .right {
      width: 100%;
      height: 80vw;
      .image0 {
        width: 45vw;
        height: 48vw;
      }
      .image1 {
        left: 52vw;
        width: 45vw;
        height: 48vw;
      }
      .image2 {
        top: 57vw;
        width: 45vw;
        height: 40vw;
      }
      .image3 {
        top: 52vw;
        left: 52vw;
        width: 45vw;
        height: 48vw;
      }
    }
  }
}