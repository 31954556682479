.logo {
  height: 6vw;
  width: 11vw;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: contain;
  @media (max-width: 500px) {
    height: 25vw;
    width: 36vw;
  }
}
