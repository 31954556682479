.toggleField {
  position: relative;
  padding-top: 2.2vw;
  display: flex;
  input[type='checkbox'] {
    opacity: 0;
    height: 0;
    width: 0;
  }
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    .label {
      font-family: league-gothic;
      color: #9E9E9E;
      font-size: 1.3vw;
      font-weight: 500;
      line-height: 1.5;
      position: absolute;
      top: 0;
      left: 0;
    }
    .optionOne, .optionTwo {
      font-family: myriad-pro;
      font-weight: 500;
      font-size: 1.4vw;
      letter-spacing: -.1vw;
      color: #D8D8D8;
      margin: 0 1vw;
      transition: all .25s ease-in-out;
      &.active {
        color: black;
      }
    }
    .toggle {
      width: 4vw;
      height: 1.7vw;
      border-radius: 2vw;
      background-color: #D8D8D8;
      text-align: left;
      margin-top: .3vw;
      position: relative;
      display: inline-block;
      &:before {
        background-color: black;
        height: 1.4vw;
        width: 1.4vw;
        border-radius: 100%;
        transition: all .5s ease-out;
        content: '';
        position: absolute;
        left: .15vw;
        top: .15vw;
      }
      &.active {
        &:before {
          transform: translateX(2.3vw);
        }
      }
    }
  }
  @media (max-width: 500px) {
    padding-top: 9.2vw;
    label {
      .label {
        font-size: 4vw;
      }
      .optionOne, .optionTwo {
        font-size: 4vw;
      }
      .toggle {
        width: 16vw;
        height: 6.8vw;
        border-radius: 8vw;
        background-color: #D8D8D8;
        text-align: left;
        margin-top: 1.2vw;
        position: relative;
        display: inline-block;
        &:before {
          background-color: black;
          height: 5.6vw;
          width: 5.6vw;
          border-radius: 100%;
          transition: all .5s ease-out;
          content: '';
          position: absolute;
          left: .6vw;
          top: .6vw;
        }
        &.active {
          &:before {
            transform: translateX(9.2vw);
          }
        }
      }
    }
  }
}