.imageLink {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
    padding: 3% 12%;
    .image {
        margin: 0;
        height: 44rem;
        object-fit: contain;
        object-position: center;
    }
}

@media (max-width: 1366px) {
    .imageLink {
        .image {
            height: 40rem;
        }
    }
}
@media (max-width: 1024px) {
    .imageLink {
        margin-top: 3rem;
        .image {
            height: 36rem;
        }
    }
}

@media (max-width: 767px) {
    .imageLink {
        margin-top: 1rem;
        padding: 8%;
        .image {
            height: 30rem;
        }
    }
}