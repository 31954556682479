.programDetails {
  width: 100%;
  overflow: visible;
  z-index: 0;
  position: relative;
  height: calc(100vh - 9vw);
  max-height: 75vw;
  min-height: 65vw;
  margin-bottom: 5vw;
  .container {
    width: 110%;
    transform: skew(3deg, 3deg);
    position: relative;
    top: -3vw;
    left: -3vw;
    overflow: hidden;
    height: 124%;
    outline-offset: -2px;
    .content {
      width: 100%;
      display: flex;
      transform: skew(-3deg, -3deg);
      position: relative;
      bottom: 5vw;
      height: 100%;
      .left {
        height: 100%;
        width: 45%;
        position: relative;
        max-height: 80vw;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        
      }
      .right {
        background-color: white;
        height: 100%;
        width: 55%;
        content: '';
        padding: 12vw 12vw 5vw 6vw;
        overflow: scroll;

        .heading {
          color: black;
          font-family: league-gothic;
          font-size: 1.8vw;
          line-height: 1.5;
          opacity: 0;
          transition: all .7s ease-in-out;
          transform: translateX(-100px);
        }
        .description {
          color: #6D6D6D;
          font-family: myriad-pro;
          font-size: 1.2vw;
          line-height: 1.5;
          margin-bottom: 2vw;
          opacity: 0;
          transition: all .7s ease-in-out .2s;
          transform: translateX(-100px);
        }
        ul {
          list-style-type: none;
          margin: 0 0 4vw 0;
          padding: 0;
          li {
            list-style-type: none;
            opacity: 0;
            transition: all .7s ease-in-out;
            transform: translateX(100px);
            a {
              text-decoration: none;
              color: #6D6D6D;
              font-weight: 900;
              font-family: myriad-pro;
              font-size: 1.5vw;
              line-height: 1.5;
              text-transform: uppercase;
              margin: 0;
              padding: 0;
              display: flex;
              align-items: center;
              transition: all .25s ease-in-out;
              &:hover {
                color: black;
              }
              img {
                width: 3vw;
                height: 3vw;
                object-fit: contain;
                margin-right: 1vw;
              }
            }
          }
        }
        .salaryHeading {
          color: #0056B8;
          font-weight: 900;
          font-family: myriad-pro;
          font-size: 1.8vw;
          line-height: 1.5;
          margin: 0;
          padding: 0;
          opacity: 0;
          transition: all .7s ease-in-out .4s;
          transform: translateY(100px);
        }
        .salarySource {
          color: #6D6D6D;
          font-family: myriad-pro;
          font-size: 1.1vw;
          line-height: 1.5;
          margin: 0;
          padding: 0;
          opacity: 0;
          transition: all .7s ease-in-out .6s;
          transform: translateY(100px);
        }
        .salary {
          color: black;
          font-weight: 900;
          font-family: myriad-pro;
          font-size: 5vw;
          line-height: 1.5;
          margin: 0;
          padding: 0;
          opacity: 0;
          transition: opacity .7s ease-in-out .8s,
          transform .7s ease-in-out .8s;
          transform: translateY(100px);
        }
        .ctaContainer {
          display: flex;
          margin-top: 3vw;
          margin-bottom: 5vw;
          .cta {
            position: relative;
            cursor: pointer;
            padding: 0;
            p {
              color: #0056B8;
              font-family: league-gothic;
              font-size: 1.6vw;
              line-height: 1.5;
              transition: all ease-in .2s;
            }
            .arrow {
              position: absolute;
              right: -5vw;
              top: -3.4vw;
              height: 8vw;
              width: 2vw;
              background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDpyZ2IoMCwwLDApOyIvPgogICAgPC9nPgo8L3N2Zz4K");
              background-position: center left;
              transform: rotate(-90deg);
              background-repeat: no-repeat no-repeat;
              z-index: 2;
              background-size: contain;
            }
            &:hover {
              .buttonLabel {
                color: rgba(0, 86, 184, 1);
              }
              .arrow {
                background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDojMDQ2NmIyOyIvPgogICAgPC9nPgo8L3N2Zz4K");
              }
            }
          }
        }
      }
    }
  }
  &.show {
    .container {
      .content {
        .right {
          .heading, .description, li, .salaryHeading, .salarySource, .salary {
            opacity: 1;
            transform: translate(0,0);
          }
        }
      }
    }
  }
  @media (max-width: 500px) {
    max-height: unset;
    height: unset;
    .container {
      width: 115%;
      left: -7vw;
      .content {
        display: block;
        .left {
          width: 100%;
          height: 80vw;
          
        }
        .right {
          height: unset;
          width: 100%;
          padding: 12vw 7vw 5vw 12vw;
          .heading {
            font-size: 6vw;
          }
          .description {
            font-size: 4vw;
            margin-bottom: 4vw;
          }
          ul {
            li {
              a {
                font-size: 4vw;
                img {
                  width: 9vw;
                  height: 9vw;
                }
              }
            }
          }
          .salaryHeading {
            font-size: 5vw;
          }
          .salarySource {
            font-size: 3.5vw;
          }
          .salary {
            font-size: 13vw;
          }
          .ctaContainer {
            justify-content: left;
            padding-left: 20vw;
            .cta {
              p {
                font-size: 6vw;
              }
              .arrow {
                right: -13vw;
                top: -7vw;
                height: 22vw;
              }
            }
          }
        }
      }
    }
  }
}