.megamenu {
  margin-bottom: 6vw;
  .link {
    font-family: league-gothic;
    color: white;
    font-size: 9vw;
    line-height: 1.5;
    text-transform: uppercase;
    cursor: pointer;
    transition: all ease-in .2s;
    position: relative;
    padding: 0;
    padding-left: 5vw;
    &.active {
      color: black;
      &:after {
        content: '';
        width: 4vw;
        height: 4vw;
        position: absolute;
        left: -1vw;
        top: 3.8vw;
        background-color: black;
      }
    }
  }
  .megaLinks {
    overflow: hidden;
    transition: all .25s ease-in-out;
    padding-left: 5vw;
    .option {
      font-family: league-gothic;
      color: white;
      font-size: 6vw;
      line-height: 1.5;
      text-transform: uppercase;
      cursor: pointer;
      transition: all ease-in .2s;
      position: relative;
      padding: 0;
      padding-left: 5vw;
      &.active {
        color: black;
        &:after {
          content: '';
          width: 3vw;
          height: 3vw;
          position: absolute;
          left: -1vw;
          top: 2vw;
          background-color: black;
        }
      }
    }
    .pageGroup {
      .subOptions {
        padding-left: 5vw;
        overflow: hidden;
        transition: all .25s ease-in-out;
        display: flex;
        flex-direction: column;
        &.show {
          overflow: visible;
        }

        .subOption {
          font-family: league-gothic;
          color: white;
          font-size: 5vw;
          line-height: 1.5;
          text-transform: uppercase;
          cursor: pointer;
          transition: all ease-in .2s;
          position: relative;
          padding: 0;
          &.active {
            color: black;
            span {
              position: relative;
              &:after {
                content: '';
                width: 3vw;
                height: 3vw;
                position: absolute;
                right: -6vw;
                top: 1.5vw;
                background-color: black;
              }
            }
          }
        }
      }
    }
  }
}