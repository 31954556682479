@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap"');
@import url("https://use.typekit.net/ypl7qow.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;732;900&display=swap");

html {
  background-color: white;
}
// body iframe {
//   z-index: -1 !important;
// }

.skip-link {
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: block;
  padding: 6px 12px;
  height: 1px;
  width: 1px;
  color: #47a8ff;
  background-color: #fff;
  border-radius: 5px;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  z-index: 999;
}

.skip-link:focus {
  height: auto;
  width: auto;
  clip: auto;
  outline: 1px #47a8ff solid;
  overflow: visible;
}
// .skipContent {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background: #0056b8;
//   height: 2.5vw;
//   left: 18%;
//   padding: 1vw;
//   position: fixed;
//   transform: translateY(-100%);
//   transition: transform 0.3s;
//   z-index: 50;
//   color: #fff;
// }

// .skipContent:focus {
//   transform: translateY(0%);
// }

// @media (max-width: 767px)
//  {
//   .skipContent {
//     height: 5vw;
//     left: 0;
//     padding: 2vw;
//   }
//  }

#root {
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto auto 1fr auto;
  width: 100vw;
  @media (max-width: 500px) {
    grid-template-rows: auto 1fr auto;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  // color: white;
  margin-bottom: 2vw;
  text-transform: uppercase;
}

h1 {
  font-size: 6vw;
  line-height: 7vw;
}

h2 {
  font-size: 5.5vw;
  line-height: 6.5vw;
}

h3 {
  font-size: 5vw;
  line-height: 6vw;
}

h4 {
  font-size: 4.5vw;
  line-height: 5.5vw;
}

h5 {
  font-size: 4vw;
  line-height: 5vw;
}

h6 {
  font-size: 3.5vw;
  line-height: 4.5vw;
}

p {
  font-size: 1.5vw;
  line-height: 1.5;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  // color: #b6b6b6;
  margin-bottom: 2vw;
}

a {
  text-decoration: none;
  color: #091c3d;
  font-family: inherit;
}

button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  font-family: inherit;
}

img {
  margin: 0;
  padding: 0;
}

input[type="text"],
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0px;
}

form.maForm {
  width: 45vw;
  margin-left: auto;
  margin-right: auto;
}
form.maForm label {
  font-family: league-gothic;
  text-transform: uppercase;
  font-size: 1.5vw;
  font-weight: 500 !important;
  line-height: 1.5;
  color: rgba(109, 109, 109, 0.8);
}
form.maForm hr {
  display: none;
}
form.maForm input {
  background-color: none !important;
  border-radius: 0 !important;
}
form.maForm select {
  background-color: transparent;
  border: none;
  border-bottom: 0.25vw solid #0056b8;
  font-family: league-gothic;
  font-size: 1.5vw;
  line-height: 1.5;
  padding: 0.5vw;
  cursor: pointer;
  display: block;
}
form.maForm .formRadioLabel {
  display: block;
}

form.maForm .formCheckboxLabel {
  display: block;
}

form.maForm .formRadioLabel input {
  margin-right: 1vw;
}

form.maForm .formCheckboxLabel input {
  margin-right: 1vw;
}

form.maForm .formRadioLabel:first-of-type,
.formCheckboxLabel:first-of-type {
  margin-top: 1vw;
}
form.maForm button {
  font-size: 1.5vw;
  font-family: myriad-pro;
  font-weight: 900;
  text-transform: uppercase;
  padding: 1vw 1.5vw;
  width: unset !important;
  color: white;
  background-color: #0466b2;
  border: none;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  -webkit-box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.6);
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.6),
    2px 2px 7px 1px rgb(0, 0, 0, 0.39);
  &:hover {
    background-color: #014070;
  }
}
form.maForm .maFormElement {
  margin-bottom: 2vw;
}
form.maForm .formText {
  width: 100%;
  font-family: league-gothic;
  text-transform: uppercase;
  border: none;
  outline: none;
  background-color: transparent;
  appearance: none;
  box-shadow: none !important;
  transition: none !important;
  border-radius: 0 !important;
  font-size: 1.5vw;
  line-height: 1.5;
  padding: 1vw 1vw 0.5vw 1vw;
  color: #091c3d;
  border-bottom: 0.25vw solid #0056b8;
  &::placeholder {
    color: rgba(109, 109, 109, 0.5);
  }
  -webkit-appearance: none;
}

form.maForm h1,
form.maForm h2,
form.maForm h3,
form.maForm h4,
form.maForm h5,
form.maForm h6,
form.maForm i {
  font-size: 1.5vw !important;
  line-height: 1.5 !important;
  font-family: league-gothic !important;
  text-transform: uppercase;
}
@media (max-width: 500px) {
  form.maForm {
    width: 100%;
    padding: 5vw;
  }
  form.maForm label {
    font-size: 4vw;
  }

  form.maForm select {
    border-bottom: 0.6vw solid #0056b8;
    font-size: 4vw;
  }
  form.maForm .formRadioLabel input {
    margin-right: 3vw;
  }
  form.maForm .formCheckboxLabel input {
    margin-right: 3vw;
  }

  form.maForm .formRadioLabel:first-of-type,
  .formCheckboxLabel:first-of-type {
    margin-top: 3vw;
  }
  form.maForm button {
    font-size: 4vw;
    padding: 3vw 4.5vw;
  }
  form.maForm .maFormElement {
    margin-bottom: 4vw;
    .formText {
      width: 100%;
      font-family: league-gothic;
      text-transform: uppercase;
      border: none;
      outline: none;
      font-size: 4vw;
      line-height: 1.5;
      padding: 2vw 2vw 1vw 2vw;
      border-bottom: 0.6vw solid #0056b8;
    }
  }
  form.maForm h1,
  form.maForm h2,
  form.maForm h3,
  form.maForm h4,
  form.maForm h5,
  form.maForm h6,
  form.maForm i {
    font-size: 4vw !important;
  }
}
