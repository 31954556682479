.ogPicker {
  height: calc(100vh - 9.4vw);
  max-height: 56vw;
  display: flex;
  .ogItem {
    height: 100%;
    flex-basis: 0;
    flex-grow: 1;
    position: relative;
    display: grid;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    overflow: hidden;
    padding: 0 2%;
    a {
      text-align: center;
      .image {
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.1);
        transition: all 0.25s ease-in-out;
      }
      .text {
        position: relative;
        font-family: league-gothic;
        font-size: 4vw;
        line-height: 1;
        text-align: center;
        color: white;
        text-transform: uppercase;
        transition: all 0.25s ease-in-out;
        text-align: center;
      }
    }
    &:hover {
      .overlay {
        background-color: rgba(0,0,0,.5);
      }
      .text {
        font-size: 5vw;
      }
    }
  }
  @media (max-width: 500px) {
    height: unset;
    max-height: unset;
    flex-direction: column;
    .ogItem {
      height: unset;
      padding: 7vw 0;
      overflow: unset;
      a {
        .text {
          font-size: 12vw;
        }
        &:hover {
          .text {
            font-size: 14vw;
          }
        }
      }
    }
  }
}