.editJob {
  .jobPost {
    padding: 0 25%;
    margin-bottom: 1vw;
    button {
      span {
        font-family: league-gothic;
        text-transform: uppercase;
        color: black;
        margin-right: 5vw;
        font-size: 1.1vw;
      }
      background-color: rgba(109, 109, 109, .2);
      border-radius: 5vw;
      width: 100%;
      padding: 1vw 5vw;
      display: flex;
      justify-content: space-between;
      border: 2px solid transparent;
      &:hover, &.active {
        border: 2px solid #0056B8;
      }
    }
  }
  form {
    margin-top: 5vw;
  }
  @media (max-width: 500px) {
    .jobPost {
      margin-bottom: 3vw;
      padding: 0 0;
      button {
        padding: 3vw 5vw;
        span {
          font-size: 4vw;
        }
      }
    }
  }
}