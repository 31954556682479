.funnelGrid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5%;
  .heading {
    font-family: league-gothic;
    color: #000005;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 2.2vw;
    line-height: 1.5;
    margin-bottom: 2vw;
    transition: all .5s ease-in-out;
    opacity: 0;
    transform: translateX(-100px);
  }
  .items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .funnel {
      width: 19%;
      height: 20vw;
      position: relative;
      margin: .5%;
      transition: all .5s ease-in-out;
      opacity: 0;
      transform: translateX(100px);
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: all .25s ease-in-out;
      }
      .name {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 23%;
        width: 100%;
        font-size: 1.2vw;
        text-transform: uppercase;
        font-family: myriad-pro;
        font-weight: 800;
        padding: 3%;
        color: white;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.3;
      }
      .nameBackground {
        position: absolute;
        bottom: -12%;
        left: 0;
        width: 120%;
        height: 50%;
        opacity: 0.7;
        transform: skew(10deg, -21deg);
        transition: all .25s ease-in-out;
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
        .nameBackground {
          transform: skew(10deg, -21deg) scale(5);
        }
      }
    }
  }
  
  &.show {
    .heading {
      opacity: 1;
      transform: translateX(0);
    }
    .items {
      .funnel {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  @media (max-width: 500px) {
    .heading {
      font-size: 5vw;
      margin-right: 0;
      margin-bottom: 8vw;
      margin-top: 3vw;
    }
    .items {
      .funnel {
        margin: 1%;
        width: 47%;
        height: 46vw;
        .name {
          font-size: 3vw;
          height: 27%;
        }
        .nameBackground {
          height: 56%;
        }
      }
    }
  }
}