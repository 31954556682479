.buttonContainer {
  .button {
    font-size: 1.7vw;
    font-family: myriad-pro;
    font-weight: 900;
    text-transform: uppercase;
    padding: 1vw 1.5vw;
    color: white;
    background-color: #0466b2;
    border: none;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    -webkit-box-shadow: inset 0px 0px 10px rgba(0,0,0,.6);
     -moz-box-shadow: inset 0px 0px 10px rgba(0,0,0,.6);
          box-shadow: inset 0px 0px 10px rgba(0,0,0,.6),
                            2px 2px 7px 1px rgb(0,0,0,.39);
    &:hover {
      background-color: #014070;
    }
  }
  @media (max-width: 500px) {
    .button {
      font-size: 4.5vw;
      padding: 2vw 3vw;
      letter-spacing: -0.25vw;
    }
  }
}
