.overview {
  width: 100%;
  .title {
    color: #0056B8;
    font-family: league-gothic;
    text-transform: uppercase;
    font-size: 1.8vw;
    line-height: 1.5;
    transition: all .7s ease-in-out;
    transform: translateX(100px);
    opacity: 0;
  }
  .heading {
    color: #0056B8;
    font-family: myriad-pro;
    text-transform: uppercase;
    font-size: 1.6vw;
    line-height: 1.5;
    transition: all .7s ease-in-out .2s;
    transform: translateX(100px);
    opacity: 0;
  }
  .body {
    font-family: myriad-pro;
    color: #6D6D6D;
    font-size: 1.4vw;
    line-height: 1.5;
    margin-bottom: 3vw;
    transition: all .7s ease-in-out .4s;
    transform: translateX(100px);
    opacity: 0;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .image {
    width: 100%;
    min-height: 30vw;
    object-fit: contain;
    transition: all .7s ease-in-out .6s;
    transform: translateX(100px);
    opacity: 0;
  }
  &.show {
    .title, .heading, .body, .image {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @media (max-width: 500px) {
    padding: 0 4%;
    .title {
      font-size: 6vw;
    }
    .heading {
      font-size: 5vw;
    }
    .body {
      font-size: 4vw;
    }
    .image {
      min-height: 65vw;
    }
  }
}