.tabsTextImage {
  width: 100%;
  display: flex;
  background-color: #0056B8;
  position: relative;
  height: 49vw;
  .left {
    width: 35%;
    padding-right: 5%;
    transition: all .5s ease-in-out;
    transform: translateX(-100px);
    opacity: 0;
    position: relative;
    z-index: 1;
    padding: 7% 0% 7% 5%;
    .heading {
      font-size: 2vw;
      font-family: league-gothic;
      text-transform: uppercase;
      padding-left: 5%;
      color: white;
      line-height: 1.5;
    }
    .tab {
      display: block;
      font-family: myriad-pro;
      font-weight: 900;
      color: black;
      text-transform: uppercase;
      font-size: 1.9vw;
      line-height: 1.5;
      position: relative;
      transition: all .25s ease-in-out;
      margin-left: 1.5vw;
      margin-bottom: 1vw;
      &.active {
        color: white;
        &:before {
          content: "";
          height: 1vw;
          width: 1vw;
          background-color: white;
          position: absolute;
          left: -2vw;
          top: 1.25vw;
        }
      }
      &:hover {
        color: white;
      }
    }
  }
  .right {
    width: 43%;
    transition: all .5s ease-in-out;
    transform: translateX(100px);
    opacity: 0;
    position: relative;
    z-index: 1;
    padding: 12% 5% 7% 8%;
    overflow: scroll;
    .rightText {
      font-family: myriad-pro;
      line-height: 1.5;
      color: white;
      font-size: 1.3vw;
      transition: all .25s ease-in-out;
      opacity: 1;
      
      &.hide {
        opacity: 0;
        transition: none;
      }
      strong {
        color: white;
        font-size: 1.6vw;
        font-weight: 900;
        font-family: myriad-pro;
      }
    }
    .ctaContainer {
      display: flex;
      justify-content: left;
      position: relative;
      width: 100%;
      margin-top: 5vw;
      margin-bottom: 5vw;
      padding-right: 10%;
      .button {
        position: relative;
        cursor: pointer;
        padding: 0;
        .buttonLabel {
          font-family: league-gothic;
          text-transform: uppercase;
          font-size: 1.4vw;
          transition: all ease-in .2s;
        }
        .arrow {
          position: absolute;
          right: -5vw;
          top: -3.8vw;
          height: 8vw;
          width: 2vw;
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDpyZ2IoMCwwLDApOyIvPgogICAgPC9nPgo8L3N2Zz4K");
          background-position: center left;
          transform: rotate(-90deg);
          background-repeat: no-repeat no-repeat;
          z-index: 2;
          background-size: contain;
        }
      }
      &:hover {
        .cta {
          .buttonLabel {
            color: white;
          }
        }
      }
    }
  }
  .image {
    width: 25%;
    height: 100%;
    position: relative;
    a {
      height: 100%;
      width: 100%;
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
    .arrow {
      position: absolute;
      top: 35%;
      left: 49%;
      height: 9vw;
      width: 1vw;
      transform: rotate(180deg);
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDpyZ2IoMjU1LDI1NSwyNTUpOyIvPgogICAgPC9nPgo8L3N2Zz4K");
      background-position: center left;
      background-repeat: no-repeat no-repeat;
      z-index: 2;
      background-size: contain;
    }
  }
  .cubeOne {
    position: absolute;
    top: 0;
    left: -10vw;
    height: 20vw;
    width: 20vw;
    z-index: 0;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NTkiIGhlaWdodD0iNDU3Ij48cGF0aCBkPSJNIDAgMCBMIDM0OSAwIEwgMzQ5IDEwOCBMIDExMCAxMDggTCAxMTAgMzQ5IEwgMCAzNDkgWiBNIDM0OSAzNDkgTCAxMTAgMzQ5IEwgMTEwIDQ1NyBMIDQ1OSA0NTcgTCA0NTkgMTA4IEwgMzQ5IDEwOCBaIiBmaWxsPSJyZ2IoMjU1LCAyNTUsIDI1NSkiIG9wYWNpdHk9IjAuNTU0ODI0MTAyNDkyNTU5NSI+PC9wYXRoPjwvc3ZnPg==");
    background-repeat: no-repeat;
    background-size: contain;
    display: none;
    &.light {
      display: block;
    }
  }
  .cubeTwo {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 20vw;
    width: 20vw;
    z-index: 0;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NTkiIGhlaWdodD0iNDU3Ij48cGF0aCBkPSJNIDAgMCBMIDM0OSAwIEwgMzQ5IDEwOCBMIDExMCAxMDggTCAxMTAgMzQ5IEwgMCAzNDkgWiBNIDM0OSAzNDkgTCAxMTAgMzQ5IEwgMTEwIDQ1NyBMIDQ1OSA0NTcgTCA0NTkgMTA4IEwgMzQ5IDEwOCBaIiBmaWxsPSJyZ2IoMjU1LCAyNTUsIDI1NSkiIG9wYWNpdHk9IjAuNTU0ODI0MTAyNDkyNTU5NSI+PC9wYXRoPjwvc3ZnPg==");
    background-repeat: no-repeat;
    background-size: contain;
    display: none;
    &.light {
      display: block;
    }
  }
  &.show {
    .left, .right {
      opacity: 1;
      transform: translateX(0);
    }
  }
  &.light {
    background-color: #E0E8F5;
    .left {
      .heading {
        color: #0056B8;
      }
      .tab {
        &.active {
          color: #0056B8;
          &:before {
            background-color:#0056B8;
          }
        }
        &:hover {
          color: #0056B8;
        }
      }
    }
    .right {
      .rightText {
        color: #6D6D6D;
        strong {
          color: #0056B8;
        }
      }
      .ctaContainer {
        .cta {
          &:hover {
            .buttonLabel {
              color: #0056B8;
            }
          }
        }
      }
    }
  }
  @media (max-width: 500px) {
    display: block;
    height: unset;
    .left {
      width: 100%;
      .heading {
        font-size: 7vw;
        padding: 0 0 2% 2%;
      }
      .tab {
        font-size: 6vw;
        margin-bottom: 0vw;
        &.active {
          &:before {
            height: 3vw;
            width: 3vw;
            left: -5vw;
            top: 3.5vw;
          }
        }
      }
    }
    .right {
      width: 100%;
      padding: 1% 5% 7% 5%;
      .rightText {
        font-size: 4vw;
        margin-top: 5vw;
        strong {
          font-size: 4.6vw;
        }
      }
      .ctaContainer {
        margin-top: 10vw;
        margin-bottom: 10%;
        .button {
          .buttonLabel {
            font-size: 4vw;
          }
          .arrow {
            right: -13vw;
            top: -6.5vw;
            height: 22vw;
          }
        }
      }
    }
    .image {
      height: 80vw;
      width: 100%;
      .arrow {
        height: 18vw;
        width: 2.4vw;
      }
    }
    .cubeOne, .cubeTwo {
      height: 40vw;
      width: 40vw;
      z-index: 0;
    }
  }
}