.stats {
  padding: 10vw 8vw 8vw 10vw;
  position: relative;
  .top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3vw;
    border-bottom: 2px solid black;
    padding-bottom: 12vw;
    z-index: 1;
    position: relative;
    .leftColumn {
      text-transform: uppercase;
      font-family: league-gothic;
      color:black;
      font-size: 2.3vw;
      line-height: 1.3;
      transition: all .7s ease-out;
      opacity: 0;
      transform: translateY(100px);
    }
    .rightColumn {
      opacity: 0;
      transform: translateY(100px);
      transition: all .7s ease-out .2s;
      .rightText {
        font-family: myriad-pro;
        font-size: 1.7vw;
        line-height: 1.5;
      }
      .ctaContainer {
        display: flex;
        justify-content: left;
        position: relative;
        width: 100%;
        margin-top: 5vw;
        margin-bottom: 5vw;
        padding-right: 10%;
        .cta {
          position: relative;
          cursor: pointer;
          padding: 0;
          .buttonLabel {
            font-family: league-gothic;
            text-transform: uppercase;
            font-size: 1.5vw;
            transition: all ease-in .2s;
            color: #0056B8;
          }
          .arrow {
            position: absolute;
            right: -6vw;
            top: -3.7vw;
            height: 8vw;
            width: 2vw;
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDpyZ2IoMCwwLDApOyIvPgogICAgPC9nPgo8L3N2Zz4K");
            background-position: center left;
            transform: rotate(-90deg);
            background-repeat: no-repeat no-repeat;
            z-index: 2;
            background-size: contain;
          }
          &:hover {
            .buttonLabel {
              color: black;
            }
          }
        }
      }
    }
  }
  .bottom {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 3vw;
    opacity: 0;
    transition: .5s;
    &.show {
      opacity: 1;
    }
    .stat {
      display: flex;
      margin: 0 2vw 0 2vw;
      position: relative;
      width: 18vw;
      height: 10vw;
      .icon {
        width: 5vw;
        height: 5vw;
        margin-top: 1vw;
        margin-right: .7vw;
        object-fit: contain;
      }
      .statContent {
        .number {
          .countup {
            font-family: league-gothic;
            font-size: 8vw;
            line-height: 1;
            color: #0056B8;
          }
          .unit {
            font-family: league-gothic;
            font-size: 2.5vw; 
            color: #0056B8;
          }
        }
        .statText {
          font-size: 2vw;
          text-transform: uppercase;
          font-family: league-gothic;
        }
      }
    }
  }
  &.show {
    .top {
      .leftColumn, .rightColumn {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
  .cube {
    z-index: 0;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NTkiIGhlaWdodD0iNDU3Ij48cGF0aCBkPSJNIDAgMCBMIDM0OSAwIEwgMzQ5IDEwOCBMIDExMCAxMDggTCAxMTAgMzQ5IEwgMCAzNDkgWiBNIDM0OSAzNDkgTCAxMTAgMzQ5IEwgMTEwIDQ1NyBMIDQ1OSA0NTcgTCA0NTkgMTA4IEwgMzQ5IDEwOCBaIiBmaWxsPSJyZ2JhKDIxNiwyMTYsMjE2LDEpIiBvcGFjaXR5PSIwLjU1NDgyNDEwMjQ5MjU1OTUiPjwvcGF0aD48L3N2Zz4=");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 8vw;
    left: 13vw;
    height: 25vw;
    width: 25vw;
  }

  @media (max-width: 500px) {
    padding: 10vw 8vw 10vw 8vw;
    .top {
      grid-template-columns: 1fr;
      .leftColumn {
        font-size: 7vw;
        margin-bottom: 5vw;
      }
      .rightColumn {
        .rightText {
          font-size: 4vw;
        }
        .ctaContainer {
          margin-top: 10vw;
          margin-bottom: 10%;
          .cta {
            .buttonLabel {
              font-size: 6vw;
            }
            .arrow {
              right: -13vw;
              top: -7.5vw;
              height: 22vw;
            }
          }
        }
      }
    }
    .bottom {
      flex-direction: column;
      justify-content: center;
      padding-top: 6vw;
      .stat {
        width: 54vw;
        height: 30vw;
        align-items: center;
        margin-bottom: 8vw;
        .icon {
          width: 20vw;
          height: 20vw;
        }
        .statContent {
          .number {
            .countup {
              font-size: 24vw;
            }
            .unit {
              font-size: 7.5vw;
            }
          }
          .statText {
            font-size: 6vw;
          }
        }
      }
    }
    .cube {
      height: 50vw;
      width: 50vw;
    }
  }
}