.subNav {
  display: flex;
  gap: 3vw;
  align-items: center;
  justify-content: center;
  background-color: #0466b2;
  width: 100%;
  padding: .5vw;
  box-shadow: 0.1vw 0.1vw 0.7vw 0.2vw rgb(0 0 0 / 30%);
  .page {
    position: relative;
    .subNavPage {
      color: white;
      font-family: myriad-pro;
      font-style: italic;
      font-size: .9vw;
      font-weight: 900;
      text-transform: uppercase;
      padding: 0;
      transition: .1s ease-in-out;
      line-height: 1.5;
      &.active, &:hover {
        color: black;
      }
    }
  }
  @media (max-width: 500px) {
    display: none;
  }
}
