.links {
  display: grid;
  grid-auto-flow: column;
  gap: 2vw;
  align-items: center;
  .link {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1.2vw;
    color: black;
    font-family: myriad-pro;
    font-weight: 900;
    transition: all 0.25s ease-in-out;
    &.active, &:hover {
      color: #0056B8;
    }
  }
  @media (max-width: 500px) {
    display: none;
  }
}
