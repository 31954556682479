.flexibleContent {
  padding: 3vw 0vw 3vw 6vw;
  position: relative;
  width: 100%;
  ul,
  li {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  .title {
    font-family: league-gothic;
    color: #000008;
    text-transform: uppercase;
    font-weight: 200;
    font-size: 3vw;
    transition: all 0.7s ease-out;
    transform: translateX(-100px);
    opacity: 0;
  }
  .body {
    display: flex;
    .sidebar {
      width: 27vw;
      padding-top: 1vw;
      .stick {
        position: sticky;
        top: 15vw;
        .mobileMenu {
          display: none;
        }
        .section {
          transition: all 0.7s ease-out;
          transform: translateX(-100px);
          opacity: 0;
          .heading {
            font-family: league-gothic;
            color: #6d6d6d;
            transition: all 0.25s ease-in-out;
            text-transform: uppercase;
            font-weight: 200;
            font-size: 1.7vw;
            line-height: 1.5;
            margin: 1vw 0 0vw 0;
            &.active,
            &:hover {
              color: #0056b8;
            }
          }
          .links {
            padding-left: 2vw;
            margin-bottom: 0;
            max-height: 0;
            overflow: hidden;
            transition: all 0.5s;
            .link {
              display: block;
              font-family: myriad-pro;
              text-transform: uppercase;
              font-weight: 200;
              font-size: 1.3vw;
              line-height: 1.5;
              border: none;
              background: none;
              cursor: pointer;
              color: #6d6d6d;
              transition: all 0.25s ease-in-out;
              position: relative;
              margin-top: 1vw;
              text-align: left;
              &.active {
                color: black;
                &:before {
                  content: "";
                  background-color: #0056b8;
                  height: 1vw;
                  width: 1vw;
                  position: absolute;
                  left: -1.4vw;
                  top: 0.5vw;
                }
              }
              &:hover {
                color: #0056b8;
              }
            }
            .subLinks {
              padding-left: 1.5vw;
              transition: all 0.5s;
              max-height: 0;
              overflow: hidden;
              .subLink,
              .subSubLink {
                display: block;
                font-family: myriad-pro;
                text-transform: uppercase;
                color: black;
                font-weight: 200;
                font-size: 1.3vw;
                line-height: 1.5;
                border: none;
                background: none;
                cursor: pointer;
                color: #d8d8d8;
                transition: all 0.25s ease-in-out;
                position: relative;
                &.active,
                &:hover {
                  color: black;
                }
              }
            }
          }
        }
      }
    }
    .content {
      margin-top: 1.8vw;
      width: 65.5vw;
      overflow: hidden;
      padding-left: 3vw;
      padding-right: 1.5vw;
      border-left: 0.2vw solid black;
      section {
        padding-top: 0 !important;
      }
    }
  }
  &.show {
    .title {
      transform: translateX(0);
      opacity: 1;
    }
    .body {
      .sidebar {
        .section {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }
  }
  @media (max-width: 500px) {
    min-height: calc(100vh - 23vw);
    padding: 5vw 0vw 5vw 0vw;
    overflow: hidden;

    .title {
      font-size: 6vw;
      margin-left: 6vw;
    }
    .body {
      display: block;
      .sidebar {
        width: 88%;
        border: 0.6vw solid black;
        padding: 2% 0%;
        margin-left: 6vw;
        max-height: 10.9vw;
        overflow: hidden;
        position: absolute;
        z-index: 5;
        background-color: white;
        transition: max-height 0.7s;
        &.open {
          max-height: 300vh;
          .mobileMenu {
            .chevron {
              transform: rotate(-135deg);
            }
          }
        }
        .stick {
        top: 0;
          .mobileMenu {
            display: block;
            font-family: league-gothic;
            color: #0056b8;
            text-transform: uppercase;
            font-weight: 200;
            font-size: 5vw;
            margin-bottom: 2vw;
            padding: 0 0 2.5% 3%;
            position: relative;
            cursor: pointer;
            width: 100%;
            text-align: left;
            border-bottom: 0.6vw solid black;
            .chevron {
              position: absolute;
              transform: rotate(45deg);
              border-right: 0.6vw solid #0056b8;
              border-bottom: 0.6vw solid #0056b8;
              width: 3vw;
              height: 3vw;
              right: 3%;
              top: 12%;
              transition: 0.7s ease-out;
            }
          }
          .section {
            margin-bottom: 3vw;
            padding-left: 3%;
            .heading {
              font-size: 5vw;
            }
            .links {
              padding-left: 4vw;
              .link {
                font-size: 4.5vw;
                margin-top: 1vw;
                &.active {
                  color: black;
                  &:before {
                    content: "";
                    background-color: #0056b8;
                    height: 2vw;
                    width: 2vw;
                    position: absolute;
                    left: -3.8vw;
                    top: 2.5vw;
                  }
                }
              }
              .subLinks {
                .subLink,
                .subSubLink {
                  font-size: 4.5vw;
                }
              }
            }
          }
        }
      }
      .content {
        width: 100%;
        border: none;
        padding-left: 0;
        padding-top: 20%;
      }
    }
  }
}
