.dropdownForm {
  padding-top: 8%;
  text-align: center;
  width: 100%;
  height: 90vh;
  overflow: scroll;
  position: absolute;
  top: 9vw;
  transform: translateY(-150%);
  transition: all .25s ease-in-out;
  left: 0;
  background-color: white;
  z-index: 0;
  box-shadow: 0 0 10px 2px rgba(0,0,0,.1);
  &.show {
    transform: translateY(0);
  }
  .close {
    color: #0056B8;
    font-size: 2vw;
    font-family: myriad-pro;
    font-weight: 900;
    position: absolute;
    top: 3vw;
    right: 3vw;
  }
  form {
    width: 100%;
    padding: 0 30%;
    display: flex;
    flex-wrap: wrap;
    .textField {
      width: 50%;
      padding-right: 2vw;
      margin-bottom: 2vw;
      input {
        width: 100%;
        font-family: myriad-pro;
        font-weight: 900;
        border: none;
        outline: none;
        font-size: 1.4vw;
        line-height: 1.5;
        padding: 1vw 1vw .5vw 1vw;
        color: black;
        border-bottom: .25vw solid #0056B8;
        &::placeholder {
          color: #9E9E9E;
          opacity: 1;
        }
        -webkit-appearance: none;
      }
    }
    .checkboxListField {
      margin: 0;
      width: 100%;
      padding-right: 2vw;
      position: relative;
      fieldset {
        legend {
          font-family: myriad-pro;
          color: #9E9E9E;
          font-weight: 900;
          font-size: 1.4vw;
          font-weight: 900;
          margin-bottom: 1vw;
          padding: .2vw 0 0 0;
          line-height: 1.5;
          text-align: left;
        }
        .checkboxField {
          margin: 0;
          width: 100%;
          padding-right: 2vw;
          cursor: pointer;
          position: relative;
          label {
            font-family: myriad-pro;
            font-weight: 900;
            font-size: 1.4vw;
            color: #0056B8;
            line-height: 1.5;
            cursor: pointer;
            display: flex;
            padding-left: 2vw;
            padding-top: .2vw;
            position: relative;
            .box {
              background-color: #D8D8D8;
              width: 1.2vw;
              height: 1.2vw;
              margin-right: 2vw;
              margin-top: .25vw;
              position: absolute;
              top: .3vw;
              left: 0;
              &:before {
                content: '';
                position: absolute;
                top: .15vw;
                left: .4vw;
                height: .8vw;
                width: .4vw;
                transform: rotate(40deg);
                border-right: .25vw solid #0056B8;
                border-bottom: .25vw solid #0056B8;
                opacity: 0;
                transition: opacity .25s ease-in-out;
              }
              &.active {
                opacity: 1;
                &:before {
                  opacity: 1;
                }
              }
            }
          }
          input[type="checkbox"] {
            opacity: 0;
            width: 0;
            height: 0;
            position: absolute;
          }
        }
      }
    }
    .message {
      width: 100%;
      padding-right: 2vw;
      textarea {
        font-family: myriad-pro;
        width: 100%;
        font-weight: 900;
        border: none;
        outline: none;
        font-size: 1.4vw;
        line-height: 1.5;
        padding: 1vw 1vw .5vw 1vw;
        color: black;
        border-bottom: .25vw solid #0056B8;
        &::placeholder {
          color: #9E9E9E;
          opacity: 1;
        }
        -webkit-appearance: none;
      }
    }
    .ctaContainer {
      display: flex;
      justify-content: left;
      position: relative;
      width: 100%;
      margin-top: 4vw;
      margin-bottom: 4vw;
      padding-right: 10%;
      .cta {
        position: relative;
        cursor: pointer;
        padding: 0;
        .buttonLabel {
          font-family: myriad-pro;
          font-size: 1.4vw;
          font-weight: 900;
          transition: all ease-in .2s;
          color: #0056B8;
          line-height: 1.5;
        }
        .arrow {
          position: absolute;
          right: -5vw;
          top: -3.7vw;
          height: 8vw;
          width: 2vw;
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDojMDkxQzNEOyIvPgogICAgPC9nPgo8L3N2Zz4K");
          background-position: center left;
          transform: rotate(-90deg);
          background-repeat: no-repeat no-repeat;
          z-index: 2;
          background-size: contain;
        }
        &:hover {
          .buttonLabel {
            color: rgba(0, 86, 184, 1);
          }
          .arrow {
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDojMDQ2NmIyOyIvPgogICAgPC9nPgo8L3N2Zz4K");
          }
        }
      }
    }
    .formMessage {
      font-family: myriad-pro;
      color: #9E9E9E;
      font-weight: 900;
      font-size: 1.4vw;
      font-weight: 900;
      margin: 1vw 0;
      padding: .2vw 0 0 0;
      line-height: 1.5;
      text-align: left;
    }
  }
  &.show {

  }
  @media (max-width: 500px) {
    padding-top: 30%;
    .close {
      font-size: 5vw;
      top: 20vw;
      right: 6vw;
    }
    form {
      padding: 0 10%;
      .textField {
        width: 100%;
        padding-right: 0;
        margin-bottom: 6vw;
        input {
          font-size: 4vw;
          border-bottom: .5vw solid #0056B8;
          padding: 2vw 2vw 1vw 2vw;
        }
      }
      .checkboxListField {
        fieldset {
          legend {
            font-size: 4vw;
          }
          .checkboxField {
            label {
              font-size: 4vw;
              padding-left: 8vw;
              padding-top: 1.2vw;
              .box {
                background-color: #D8D8D8;
                width: 4.8vw;
                height: 4.8vw;
                margin-right: 8vw;
                margin-top: 1vw;
                position: absolute;
                top: .6vw;
                left: 0;
                &:before {
                  content: '';
                  position: absolute;
                  top: .60vw;
                  left: 1.6vw;
                  height: 3.2vw;
                  width: 1.2vw;
                  transform: rotate(40deg);
                  border-right: .75vw solid black;
                  border-bottom: .75vw solid black;
                  opacity: 0;
                  transition: opacity .25s ease-in-out;
                }
                &.active {
                  opacity: 1;
                  &:before {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
      .message {
        padding-right: 0;
        textarea {
          font-size: 4vw;
          border-bottom: .5vw solid #0056B8;
          padding: 2vw 2vw 1vw 2vw;
        }
      }
      .ctaContainer {
        margin-top: 15vw;
        .cta {
          .buttonLabel {
            font-size: 4.5vw;
          }
          .arrow {
            right: -13vw;
            top: -8vw;
            height: 22vw;
          }
        }
      }
      .formMessage {
        font-size: 4vw;
      }
    }
  }
}