.tenFunnels {
  background-color: #D8D8D8;
  padding-top: 7vw;
  width: 100%;
  overflow: hidden;
  position: relative;
  max-height: 46vw;
  .backgroundImage {
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.1);
  }
  .scrollers {
    display: grid;
    opacity: 0;
    visibility: hidden;
    transition: all .25s ease-in-out;
    .attributes {
      display: flex;
      z-index: 2;
      position: relative;
      .attribute {
        white-space: nowrap;
        margin: 0 .5vw 0 .5vw;
        color: white;
        cursor: pointer;
        font-family: league-gothic;
        font-size: 4vw;
        text-transform: uppercase;
        line-height: 1.17;
        &:hover {
          color: #0056B8;
          text-decoration: none;
        }
      }
    }
    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
  .filler {
    min-height: 5vw;
  }
  .cta {
    position: relative;
    display: flex;
    width: 30%;
    margin-left: 35%;
    margin-bottom: 7vw;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    p {
      color: white;
      font-size: 1.6vw;
      font-family: league-gothic;
      text-transform: uppercase;
      transition: all 0.25s ease-in-out;
      line-height: 1.5;
      text-align: center;
    }
    .arrowContainer {
      height: 6vw;
      overflow: hidden;
      .arrow {
        height: 7vw;
        width: 1vw;
        transition: all 0.25s ease-in-out;
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDpyZ2IoMjU1LDI1NSwyNTUpOyIvPgogICAgPC9nPgo8L3N2Zz4K");
        background-position: center left;
        background-repeat: no-repeat no-repeat;
        z-index: 2;
        background-size: contain;
        transform: translateY(-25%);
      }
    }
    
    &:hover {
      p {
        color: #0466b2;
      }
      .arrow {
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDojMDQ2NmIyOyIvPgogICAgPC9nPgo8L3N2Zz4K");
      }
    }
  }

  @media (max-width: 500px) {
    padding-top: 25vw;
    max-height: unset;
    .desktopScrollers {
      display: none;
    }
    .scrollers {
      .attributes {
        .attribute {
          font-size: 15vw;
        }
      }
    }
    .filler {
      min-height: 10vw;
    }
    .cta {
      width: 90%;
      margin: 10vw 0 20vw 5%;
      p {
        font-size: 7vw;
        
      }
      .arrowContainer {
        height: 25vw;
        margin-top: 2vw;
        .arrow {
          height: 25vw;
          width: 2vw;
          transform: translateY(-15%);
        }
      }
    }
  }
}