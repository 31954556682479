.stepsToApply {
 display: flex;
 flex-direction: column;
 padding: 3vw 0;
  width: 100%;
  .title {
    font-family: league-gothic;
    font-size: 2.25vw;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
  }
  .steps {
    display: flex;
    justify-content: center;
    position: relative;
    height: 25vw;
    .line {
      color: rgba(216, 216, 216, .8);
      height: 2%;
      width: 0%;
      position: absolute;
      left: 25%;
      top: 47%;
      transition: all 1.5s 3s;
      opacity: 0;
      &.animate {
        opacity: 1;
        width: 50%;
      }
    }
    .step {
      height: 100%;
      flex-shrink: 0;
      width: 8%;
      min-width: 13vw;
      margin: 0 3% 0 3%;
      text-align: center;
      position: relative;
      transition: all 3s;
      .graphics {
        position: relative;
        width: 99%;
        height: 89%;
        background-color: white;
        img {
          position: absolute;
          top: 37%;
          left: 33%;
          width: 34%;
          height: 34%;
          object-fit: contain;
          opacity: .2;
          transition: all 3s;
        }
        .number {
          background-color: rgba(0, 86, 184, 1);
          border-radius: 15%;
          position: absolute;
          top: 19%;
          left: 37%;
          width: 25%;
          padding-top: 24%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          .numContainer {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            span {
              color: white;
              font-family: league-gothic;
              font-size: 1.5vw;
              top: 11%;
              left: 43%;
            }
          }
        }
      }
      .dashedBox {
        position: absolute;
        top: 0;
        left: 0%;
        height: 89%;
        width: 99%;
        transition: all 3s;
        opacity: 1;
        overflow: visible;
        background-repeat: no-repeat;
        background-size: 99%;
        background-position: center;
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjMzcHgiIGhlaWdodD0iMjU2cHgiIHZpZXdCb3g9IjAgMCAyMzMgMjU2IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPkdyb3VwIDM8L3RpdGxlPgogICAgPGcgaWQ9InByb3RvdHlwZTIiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJhZHVsdC0tLVJlYWR5LXRvLWFwcGx5LSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTgzMy4wMDAwMDAsIC0xNDUwLjAwMDAwMCkiIHN0cm9rZT0iIzAwNTZCOCI+CiAgICAgICAgICAgIDxnIGlkPSJHcm91cC0zIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4MzQuNTc1NzU4LCAxNDUxLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTIwLDI3IEwyMTAsMjcgQzIyMS4wNDU2OTUsMjcgMjMwLDM1Ljk1NDMwNSAyMzAsNDcgTDIzMCwyMzQgQzIzMCwyNDUuMDQ1Njk1IDIyMS4wNDU2OTUsMjU0IDIxMCwyNTQgTDIwLDI1NCBDOC45NTQzMDUsMjU0IDEuMzUyNzA3NWUtMTUsMjQ1LjA0NTY5NSAwLDIzNCBMMCw0NyBDLTEuMzUyNzA3NWUtMTUsMzUuOTU0MzA1IDguOTU0MzA1LDI3IDIwLDI3IFoiIGlkPSJSZWN0YW5nbGUiIHN0cm9rZS13aWR0aD0iMiIgb3BhY2l0eT0iMC40OTY1ODc4NDQiIHN0cm9rZS1kYXNoYXJyYXk9IjciPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIGZpbGw9IiMwMDU2QjgiIHg9Ijg1LjQyNDI0MjQiIHk9IjAiIHdpZHRoPSI1NSIgaGVpZ2h0PSI1NSIgcng9IjgiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+");
        &.hide {
          opacity: 0;
        }
        circle {
          fill: white;
          stroke-dasharray: unset;
          stroke-width: 2;
          stroke: rgba(0, 86, 184, 1);
        }
      }
      .solidBox {
        position: absolute;
        top: 0;
        left: 0%;
        height: 89%;
        width: 99%;
        transition: all 3s;
        opacity: 0;
        overflow: visible;
        background-repeat: no-repeat;
        background-size: 99%;
        background-position: center;
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjM2cHgiIGhlaWdodD0iMjU4cHgiIHZpZXdCb3g9IjAgMCAyMzYgMjU4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPkdyb3VwIDM8L3RpdGxlPgogICAgPGcgaWQ9InByb3RvdHlwZTIiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJhZHVsdC0tLVJlYWR5LXRvLWFwcGx5LSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTgzMi4wMDAwMDAsIC0xNDUwLjAwMDAwMCkiIHN0cm9rZT0iIzAwNTZCOCI+CiAgICAgICAgICAgIDxnIGlkPSJHcm91cC0zIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4MzQuNTc1NzU4LCAxNDUxLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZSIgc3Ryb2tlLXdpZHRoPSI1IiB4PSIwIiB5PSIyNyIgd2lkdGg9IjIzMCIgaGVpZ2h0PSIyMjciIHJ4PSIyMCI+PC9yZWN0PgogICAgICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZSIgZmlsbD0iIzAwNTZCOCIgeD0iODUuNDI0MjQyNCIgeT0iMCIgd2lkdGg9IjU1IiBoZWlnaHQ9IjU1IiByeD0iOCI+PC9yZWN0PgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=");
        &.show {
          opacity: 1;
        }
        circle {
          fill: white;
          stroke-dasharray: unset;
          stroke-width: 2;
          stroke: rgba(0, 86, 184, 1);
        }
      }
      .text {
        font-family: myriad-pro;
        font-size: 1vw;
        line-height: 1.5;
        font-weight: 800;
        text-align: center;
        opacity: .2;
        transition: all 3s;
      }
      &.animate {
        svg {
          .dashed {
            stroke-dasharray: unset;
            stroke-width: 2;
            stroke: rgba(0, 86, 184, 1);
          }
        }
        img {
          opacity: 1;
        }
        .text {
          opacity: 1;
        }
      }

    }
  }
  .ctaContainer {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    margin-top: 5vw;
    // margin-bottom: 5vw;
    .cta {
      position: relative;
      cursor: pointer;
      
      padding: 1vw 1.5vw;
      color: white;
      background-color: #0466b2;
      border: none;
      cursor: pointer;
      transition: all 0.25s ease-in-out;
      -webkit-box-shadow: inset 0px 0px 10px rgba(0,0,0,.6);
      -moz-box-shadow: inset 0px 0px 10px rgba(0,0,0,.6);
            box-shadow: inset 0px 0px 10px rgba(0,0,0,.6),
                              2px 2px 7px 1px rgb(0,0,0,.39);
      .buttonLabel {
        text-transform: uppercase;
        transition: all ease-in .2s;
        font-size: 1.7vw;
        font-family: league-gothic;
        text-transform: uppercase;
      }
      .arrow {
        position: absolute;
        right: -5vw;
        top: -3.5vw;
        height: 8vw;
        width: 2vw;
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDpyZ2IoMCwwLDApOyIvPgogICAgPC9nPgo8L3N2Zz4K");
        background-position: center left;
        transform: rotate(-90deg);
        background-repeat: no-repeat no-repeat;
        z-index: 2;
        background-size: contain;
      }
      &:hover {
        background-color: #014070;
      }
    }
  }
  @media (max-width: 500px) {
    padding-top: 10%;
    .title {
      font-size: 7vw;
       margin-bottom: 12vw;
    }
    .steps {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 6vw;
      position: relative;
      height: unset;
      align-items: center;
      .line {
        display: none;
      }
      .step {
        height: 60vw;
        width: 60%;
        margin-bottom: 12vw;
        .graphics {
          background-size: 79%;
          width: 99%;
          height: 89%;
          img {
            position: absolute;
            top: 37%;
            left: 33%;
            width: 34%;
            height: 34%;
            object-fit: contain;
            opacity: .2;
            transition: all 3s;
          }
          .number {
            border-radius: 15%;
            top: 2%;
            left: 40%;
            width: 19.5%;
            padding-top: 19%;
            .numContainer {
              span {
                font-size: 3.5vw;
              }
            }
          }
        }
        .solidBox, .dashedBox {
          top: 0;
          left: 10.2%;
          height: 90%;
          width: 80%;
          background-size: 99%;
        }
        .text {
          font-size: 4vw;
          margin-top: 3vw;
        }
        &.animate {
          svg {
            .dashed {
              stroke-dasharray: unset;
              stroke-width: 2;
              stroke: rgba(0, 86, 184, 1);
            }
          }
          img {
            opacity: 1;
          }
          .text {
            opacity: 1;
          }
        }
  
      }
    }
    .ctaContainer {
      margin-top: 10vw;
      margin-bottom: 10%;
      .cta {
        padding: 4vw 4.5vw;
        .buttonLabel {
          font-size: 6vw;
        }
      }
    }
  }
}