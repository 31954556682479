.table {
  padding: 5vw 5vw;
  width: 100%;
  position: relative;
  .topRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3vw;
    padding: 0 25%;
    .heading {
      font-family: league-gothic;
      font-size: 1.7vw;
      color: black;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
      line-height: 1.5;
    }
  }
  .tableContainer {
    .titleRow {
      display: flex;
      width: 100%;
      border-bottom: .2vw solid #979797;
      padding-bottom: 1vw;
      .title {
        flex: 1;
        font-family: myriad-pro;
        font-weight: 900;
        color: black;
        font-size: 1.1vw;
        padding-right: 2vw;
      }
    }
    .dataRow {
      display: flex;
      width: 100%;
      border-bottom: .2vw solid #979797;
      padding: 2vw 0;
      transition: all .5s ease-in-out;
      opacity: 0;
      transform: translateX(100px);
      .rowItem {
        flex: 1;
        padding-right: 4%;
        align-items: center;
        div {
          display: flex;
          align-items: center;
          height: 100%;
          font-family: myriad-pro;
          font-size: 1vw;
          color: #7C7C7C;
          margin: 0;
          line-height: 1.5;
        }
        &:first-of-type {
          div {
            color: #6D6D6D;
            font-weight: bold;
          }
        }
        .apply {
          background-color: #0056B8;
          justify-content: center;
          color: white;
          font-size: 1vw;
          font-weight: bold;
          width: fit-content;
          padding: .75vw 3vw;
          cursor: pointer;
          transition: all .25s ease-in-out;
          &:hover {
            background-color: black;
          }
        }
      }
    }
  }
  .mobile {
    display: none;
  }
  &.show {
    .tableContainer {
      .dataRow {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  @media (max-width: 500px) {
    padding: 8vw;
    .topRow {
      width: unset;
      display: block;
      padding: 0 0;
      .heading {
        font-size: 6vw;
      }
      .search {
        font-size: 4vw;
        background-size: 3vw;
        width: 100%;
        padding: 3vw 2vw;
      }
      .postButton {
        font-size: 4vw;
        margin: 3vw 0;
      }
    }

    .tableContainer {
      display: none;
    }
    .mobile {
      display: block;
      .dataRow {
        width: 100%;
        border-bottom: .2vw solid #979797;
        padding: 2vw 0;
        transition: all .5s ease-in-out;
        opacity: 0;
        transform: translateX(100px);
        .rowItem {
          padding-right: 4%;
          display: flex;
          margin: 0 0 5vw 0;
          .columnTitle {
            background-color: white;
          }
          div {
            height: 100%;
            font-family: myriad-pro;
            font-size: 4vw;
            color: #7C7C7C;
            line-height: 1.5;
            flex: 1;
          }
          &:first-of-type {
            a, div {
              color: #6D6D6D;
              font-weight: bold;
            }
          }
          .apply {
            background-color: #0056B8;
            justify-content: center;
            color: white;
            font-size: 4vw;
            font-weight: bold;
            width: fit-content;
            padding: .75vw 3vw;
            cursor: pointer;
            transition: all .25s ease-in-out;
            &:hover {
              background-color: black;
            }
          }
        }
      }
    }
    &.show {
      .mobile {
        .dataRow {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }
}