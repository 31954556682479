.pdfViewer {
  width: 100%;
  padding: 5vw;
  height: 60vw;
  .pdfContainer {
    height: 100%;
    width: 100%;
    .pdfWrapper {
      position: relative;
      padding-bottom: 55%;
      height: 0;
      height: 0;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overscroll-behavior: contain;
      }
    }
  }
}

@media (max-width: 767px) {
  .pdfViewer {
    height: 100vw;
    .pdfContainer {
      .pdfWrapper {
        height: 100%;
      }
    }
  }
}