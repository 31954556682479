.embedForm {
  padding: 8vw 0 0 0;
  .heading {
    color: #0056B8;
    font-family: league-gothic;
    text-transform: uppercase;
    font-size: 2.2vw;
    padding: 0 20%;
    line-height: 1.5;
    margin-bottom: 2vw;
    transition: all .7s ease-in-out;
    @media (max-width: 500px) {
      font-size: 6vw;
      padding: 0 5%;
    }
  }
}
