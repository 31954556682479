.gallery {
  position: relative;
  width: 100%;
  padding: 5%;
  .heading {
    font-family: league-gothic;
    color: black;
    font-size: 2vw;
    margin-right: 20%;
    line-height: 1.5;
    margin-bottom: 3vw;
    text-transform: uppercase;
    transition: all .5s ease-in-out;
    opacity: 0;
    transform: translateX(-100px);
  }
  .content {
    display: flex;
    position: relative;
    .featured {
      padding: 0 .5% 0 0;
      width: 50%;
      height: 40vw;
      opacity: 0;
      transform: translateX(-100px);
      transition: all .7s ease-in-out;
      &:hover {
        img {
          filter: brightness(60%);
        }
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: all .25s ease-in-out;
      }
    }
    .grid {
      width: 50%;
      display: flex;
      flex-wrap: wrap;
      height: 40vw;
      overflow: scroll;
      position: relative;
      .gridImage {
        width: 50%;
        padding: 0 1% 1% 0;
        width: 48%;
        height: 20.1vw;
        flex-shrink: 0;
        opacity: 0;
        transform: translateX(100px);
        transition: all .7s ease-in-out;
        &:hover {
          img {
            filter: brightness(60%);
          }
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all .25s ease-in-out;
        }
      }
    }
    .arrowUp, .arrowDown {
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDpyZ2IoMCwwLDApOyIvPgogICAgPC9nPgo8L3N2Zz4K");
      position: absolute;
      height: 8vw;
      width: 2vw;
      background-position: center left;
      background-repeat: no-repeat no-repeat;
      z-index: 2;
      background-size: contain;
    }
    .arrowUp {
      right: .2%;
      top: 2vw;
      transform: rotate(180deg);
      &:hover {
        transform: rotate(180deg) scale(1.2);
      }
    }
    .arrowDown {
      transform: rotate(0deg);
      right: -1.5%;
      bottom: 2vw;
      &:hover {
        transform: rotate(0deg) scale(1.2);
      }
    }
  }
  &.show {
    .heading {
      opacity: 1;
      transform: translateX(0);
    }
    .content {
      .featured, .grid > .gridImage {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  .modal {
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    transition: all .25s ease-in-out;
    .modalContainer {
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: scroll;
      width: 100%;
      height: 100%;
      .count {
        position: absolute;
        top: 8%;
        left: 14%;
        font-family: myriad-pro;
        color: white;
        font-size: 1.5vw;
        font-weight: bold;
      }
      img {
        width: 60%;
        height: 40vw;
        object-fit: contain;
      }
      .description {
        font-family: myriad-pro;
        color: white;
        font-size: 1.25vw;
        margin-top: 1vw;
        padding: 0 10%;
      }
     
     
      .close {
        position: absolute;
        color: white;
        font-family: myriad-pro;
        top: 5%;
        right: 5%;
        font-size: 3vw;
        transition: all .25s ease-in-out;
        &:hover {
          transform: rotate(0deg) scale(1.2);
        }
      }
    }
    &.open {
      visibility: visible;
      opacity: 1;
      z-index: 30;
    }
    .arrowLeft, .arrowRight {
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDpyZ2IoMjU1LDI1NSwyNTUpOyIvPgogICAgPC9nPgo8L3N2Zz4K");
      background-position: center;
      background-repeat: no-repeat no-repeat;
      background-size: contain;
      position: absolute;
      z-index: 2;
      transition: all 0.25s ease-in-out;
      height: 8vw;
      width: 3vw;
      cursor: pointer;
    }
    .arrowRight {
      top: 45%;
      right: 10%;
      transform: rotate(-90deg);
      &:hover {
        transform: rotate(-90deg) scale(1.2);
      }
    }
    .arrowLeft {
      top: 45%;
      left: 10%;
      transform: rotate(90deg);
      &:hover {
        transform: rotate(90deg) scale(1.2);
      }
    }
  }
  @media (max-width: 500px) {
    .heading {
      font-size: 6vw;
    }
    .content {
      flex-wrap: wrap;
      .featured, .grid {
        flex-shrink: 0;
        width: 100%;
        height: 80vw;
        margin-bottom: 1%;
      }
      .featured {
        margin-right: 1%;
      }
      .grid {
        .gridImage {
          height: 40.2vw;
          padding: 0 1% 1% 0;
          width: 50%;
        }
      }
      .arrowUp, .arrowDown {
        height: 15vw;
      }
      .arrowUp {
        top: 94vw;
        right: -1%;
      }
      .arrowDown {
        bottom: 13vw;
        right: -2%;
      }
      
    }
    .modal {
      .modalContainer {
        .close {
          font-size: 6vw;
          top: 8%;
          right: 10%;
        }
        .count {
          font-size: 4vw;
        }
        .description {
          font-size: 3.5vw;
          padding: 0 10%;
        }
        
        img {
          width: 80%;
          height: 53vw;
        }
      }
      .arrowRight {
        top: 69%;
        right: 33%;
        height: 15vw;
      }
      .arrowLeft {
        top: 69%;
        left: 33%;
        height: 15vw;
      }
    }
  }
}
