.rooms {
  width: 100%;
  padding: 3% 5% 3% 5%;
  display: flex;
  .left {
    padding: 8% 2% 0 0;
    .heading {
      color: black;
      font-size: 2vw;
      font-family: league-gothic;
      text-transform: uppercase;
      margin: 0 0 4vw 0;
      line-height: 1.5;
    }
    width: 30%;
    .checkboxes {
      legend {
        color: black;
        font-size: 1.25vw;
        font-family: myriad-pro;
        text-transform: uppercase;
        font-weight: 900;
        margin-bottom: 2vw;
      }
    }
    .viewAll {
      color: black;
      font-size: 1.25vw;
      font-family: myriad-pro;
      text-transform: uppercase;
      font-weight: 900;
      line-height: 1.5;
    }
  }
  .right {
    display: flex;
    flex-wrap: wrap;
    width: 71%;
    padding: 8% 0% 0 0;
    .room {
      width: 21vw;
      height: 17vw;
      padding: 1vw;
      position: relative;
      &:hover {
        filter: brightness(120%);
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      .gradient {
        position: absolute;
        top: 1vw;
        left: 1vw;
        width: 19vw;
        height: 15vw;
        background-color: rgba(0,0,0,.2);
      }
      p {
        position: absolute;
        top: 45%;
        width: 100%;
        left: 0;
        text-align: center;
        color: white;
        font-size: 1.25vw;
        padding: 0 10%;
        font-family: myriad-pro;
        font-weight: 900;
      }
    }
  }
  @media (max-width: 500px) {
    display: block;
    .left {
      .heading {
        font-size: 5vw;
        margin-bottom: 7vw;
      }
      .viewAll {
        font-size: 4vw;
        margin-bottom: 5vw;
      }
      width: 100%;
      .checkboxes {
        legend {
          font-size: 4vw;
        }
      }
    }
    .right {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 0% 0 0;
      .room {
        width: 100%;
        height: 50vw;
        padding: 0;
        position: relative;
        margin-bottom: 2vw;
        &:hover {
          filter: brightness(120%);
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
        .gradient {
          position: absolute;
          top: 0vw;
          left: 0vw;
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,.2);
        }
        p {
          position: absolute;
          top: 45%;
          width: 100%;
          left: 0;
          text-align: center;
          color: white;
          font-size: 4vw;
          font-family: myriad-pro;
          font-weight: 900;
        }
      }
    }
  }
}