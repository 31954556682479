.grid {
  position: relative;
  width: 100%;
  padding: 5% 10% 0% 10%;
  background-color: #E0E8F5;
  overflow: hidden;
  &.light {
    background-color: white;
  } 
  .heading {
    font-family: league-gothic;
    color: black;
    text-transform: uppercase;
    font-size: 2vw;
    margin-right: 20%;
    line-height: 1.5;
    margin-bottom: 6vw;
    transition: all .5s ease-in-out;
    opacity: 0;
    transform: translateX(-100px);
  }
  .arrowLeft {
    position: absolute;
    left: 4vw;
    top: 17vw;
    height: 8vw;
    width: 2vw;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDpyZ2IoMCwwLDApOyIvPgogICAgPC9nPgo8L3N2Zz4K");
    background-position: center left;
    transform: rotate(90deg);
    background-repeat: no-repeat no-repeat;
    z-index: 2;
    background-size: contain;
  }
  .arrowRight {
    position: absolute;
    right: 4vw;
    top: 17vw;
    height: 8vw;
    width: 2vw;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDpyZ2IoMCwwLDApOyIvPgogICAgPC9nPgo8L3N2Zz4K");
    background-position: center left;
    transform: rotate(-90deg);
    background-repeat: no-repeat no-repeat;
    z-index: 2;
    background-size: contain;
  }
  .items {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 100%;
    scroll-behavior: smooth;
    .item {
      flex-shrink: 0;
      text-align: center;
      width: 25%;
      margin-bottom: 5vw;
      transition: all .5s ease-in-out;
      opacity: 0;
      transform: translateX(100px);
      img {
        width: 75%;
      }
      p {
        font-family: myriad-pro;
        font-weight: 900;
        color: black;
        font-size: 1.3vw;
        line-height: 1.5;
      }
    }
  }
  &.show {
    .heading {
      opacity: 1;
      transform: translateX(0);
    }
    .items {
      .item {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  @media (max-width: 500px) {
    padding: 5% 8% 5% 8%;
    .heading {
      font-size: 5vw;
      margin-right: 0;
      margin-bottom: 8vw;
    }
    .arrowRight {
      right: 27vw;
      top: unset;
      bottom: 1vw;
      height: 16vw;
      width: 1vw;
    }
    .arrowLeft {
      left: 27vw;
      top: unset;
      bottom: 1vw;
      height: 16vw;
      width: 1vw;
    }
    .items {
      .item {
        margin-bottom: 10vw;
        width: 50%;
        p {
          font-size: 4vw;
          margin-top: 2vw;
        }
      }
    }
  }
}