.table {
  padding: 5vw 3vw 5vw 4vw;
  width: 100%;
  position: relative;
  min-height: 90vh;
  .sorry {
    font-family: myriad-pro;
    font-size: 1.3vw;
    color: #7C7C7C;
    width: 100%;
  }
  .topRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3vw;
    
    .heading {
      font-family: league-gothic;
      font-size: 1.7vw;
      color: black;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
      line-height: 1.5;
    }
    .search {
      font-family: myriad-pro;
      font-size: 1vw;
      padding: 0.75vw 1vw;
      width: 22vw;
      border: none;
      background-color: rgba(216, 216, 216, .38);
      margin: 0;
      background-size: 1vw;
      background-image: url("data:image/svg+xml;base64, PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogc2tldGNodG9vbCA1MS4yICg1NzUxOSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+ODc3MTIyQkEtRTBDNy00QjVELUFBOEQtNUZBOTg0MDc5RDhFPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBza2V0Y2h0b29sLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgPGcgaWQ9ImZlYXRoZXItaWNvbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTk5Ni4wMDAwMDAsIC02MDguMDAwMDAwKSIgc3Ryb2tlPSIjMDQ2NmIyIiBzdHJva2Utd2lkdGg9IjIiPgogICAgICAgICAgICA8ZyBpZD0ic2VhcmNoIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg5OTcuMDAwMDAwLCA2MDkuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8Y2lyY2xlIGlkPSJPdmFsIiBjeD0iNy41IiBjeT0iNy41IiByPSI3LjUiPjwvY2lyY2xlPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTE4LDE4IEwxMi44LDEyLjgiIGlkPSJTaGFwZSI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=");
      background-repeat: no-repeat;
      background-position: 95%;
    }
  }
  .tableContainer {
    .titleRow {
      display: flex;
      width: 100%;
      border-bottom: .2vw solid #979797;
      padding-bottom: 1vw;
      .title {
        flex: 1;
        font-family: myriad-pro;
        font-weight: 900;
        color: black;
        font-size: 1.1vw;
        padding-right: 4%;
        white-space: nowrap;
        &:first-of-type {
          flex: 1.3;
        }
        &.wide {
          flex: 2.5;
        }
        &:nth-of-type(3) {
          flex: .5
        }
        &:nth-of-type(4) {
          flex: 1.3
        }
        &:nth-of-type(5) {
          flex: 1
        }
        &:last-of-type {
          flex: .5;
        }
      }
    }
    .dataRow {
      display: flex;
      width: 100%;
      align-items: center;
      border-bottom: .2vw solid #979797;
      padding: 2vw 0;
      transition: all .5s ease-in-out;
      opacity: 0;
      transform: translateX(100px);
      .rowItem {
        flex: 1;
        padding-right: 4%;
        text-align: left;
        div {
          display: flex;
          align-items: flex-start;
          height: 100%;
          font-family: myriad-pro;
          font-size: 1vw;
          color: #7C7C7C;
          margin: 0;
          line-height: 1.5;
          width: 100%;
          p {
            font-family: myriad-pro;
            font-size: 1vw;
            color: #7C7C7C;
            margin: 0;
            line-height: 1.5;
            max-height: 5vw;
            overflow: hidden;
            width: 100%;
            transition: all .25s;
            strong{
              color: black;
            }
          }
          &.current {
            p {
              max-height: 50vw;
              overflow: scroll;
            }
          }
        }
        &:first-of-type {
          flex: 1.3;
          div {
            color: #6D6D6D;
            font-weight: 600;
            transition: all .25s ease-in-out;
            cursor: pointer;
          }
          &:hover {
            .first {
              color: black;
            }
          }
        }
        &:nth-of-type(2) {
          flex: .5
        }
        &:nth-of-type(3) {
          flex: 1.3
        }
        &:nth-of-type(4) {
          flex: 1.1;
        }
        &.wide {
          flex: 2.5;
        }
        &.current {
          &.first {
            color: black;
            position: relative;
            &:before {
              content: "";
              background-color: black;
              position: absolute;
              left: -1.5vw;
              top: .5vw;
              height: .75vw;
              width: .75vw;
            }
          }
        }
      }
      &:hover {
        .first {
          div {
            color: black;
            position: relative;
            &:before {
              content: "";
              background-color: black;
              position: absolute;
              left: -1.5vw;
              top: .5vw;
              height: .75vw;
              width: .75vw;
            }
            &:hover {
              color: #0056B8;
            }
          }
        }
      }
      .apply {
        padding: 0.5vw 1.5vw;
        color: white;
        background-color: #0466b2;
        border: none;
        height: 3vw;
        font-size: 1.3vw;
        cursor: pointer;
        transition: all 0.25s ease-in-out;
        font-family: myriad-pro;
        &:hover {
          background-color: black;
        }
      }
    }
  }
  .mobile {
    display: none;
  }
  &.show {
    .tableContainer {
      .dataRow {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  @media (max-width: 500px) {
    .sorry {
      font-family: myriad-pro;
      letter-spacing: -.05vw;
      font-size: 4vw;
      color: #7C7C7C;
    }
    .topRow {
      width: unset;
      display: block;
     
      .heading {
        font-size: 6vw;
      }
      .search {
        font-size: 4vw;
        background-size: 3vw;
        width: 100%;
        padding: 3vw 2vw;
      }
    }

    .tableContainer {
      display: none;
    }
    .mobile {
      display: block;
      .dataRow {
        width: 100%;
        border-bottom: .2vw solid #979797;
        padding: 2vw 0;
        transition: all .5s ease-in-out;
        opacity: 0;
        transform: translateX(100px);
        .rowItem {
          padding-right: 4%;
          display: flex;
          margin: 0 0 5vw 0;
          width: 100%;
          text-align: left;
          .columnTitle {
            background-color: white;
          }
          div {
            height: 100%;
            font-family: myriad-pro;
            font-size: 4vw;
            color: #7C7C7C;
            line-height: 1.5;
            flex: 1;
            p {
              font-family: myriad-pro;
              font-size: 4vw;
              color: #7C7C7C;
              max-height: 26vw;
              overflow: hidden;
              width: 100%;
              transition: all .25s;
            }
          }
          &:first-of-type {
            a, div {
              color: #6D6D6D;
              font-weight: 600;
            }
          }
          &.current {
            div {
              p {
                max-height: 100vh;
                overflow: scroll;
              }
            }
          }
          &.first {
            div {
              color: black;
            }
          }
        }
        .apply {
          width: 100%;
          padding: 0.5vw 1.5vw;
          background-color: #0466b2;
          border: none;
          height: 6vw;
          cursor: pointer;
          transition: all 0.25s ease-in-out;
          padding-right: 4%;
          display: flex;
          margin: 0 0 5vw 0;
          width: 45%;
          text-align: center;
          div {
            height: 100%;
            font-family: myriad-pro;
            font-size: 4vw;
            color: white;
            flex: 1;
          }
          &:hover {
            background-color: black;
          }
        }
      }
    }
    &.show {
      .mobile {
        .dataRow {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }
}