.googleMap {
  padding: 8vw 0 0 0;
  .heading {
    color: black;
    font-family: league-gothic;
    text-transform: uppercase;
    font-size: 2.2vw;
    padding: 0 20%;
    line-height: 1.5;
    margin-bottom: 2vw;
    transition: all .7s ease-in-out;
    @media (max-width: 500px) {
      font-size: 6vw;
      padding: 0 5%;
    }
  }
  .mapContainer {
    div {
      padding: 5% !important;
      div {
        width: 100% !important;
        height: unset !important;
        div {
          position: relative !important;
          overflow: hidden !important;
          width: 100% !important;
          padding-top: 56.25% !important;
          height: unset !important;
          iframe {
            position: absolute;
            top: 0 !important;
            left: 0 !important;
            bottom: 0 !important;
            right: 0 !important;
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
    }
  }
}
