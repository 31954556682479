.ctaContact {
  padding-top: 8%;
  text-align: center;
  width: 100%;
  .heading {
    color: #0056B8;
    font-family: league-gothic;
    text-transform: uppercase;
    font-size: 2.2vw;
    padding: 0 20%;
    line-height: 1.1;
    margin-bottom: 7vw;
    opacity: 0;
    transform: translateY(100px);
    transition: all .7s ease-in-out;
  }
  form {
    width: 100%;
    padding: 0 30%;
    display: flex;
    flex-wrap: wrap;
    .textField {
      width: 50%;
      padding-right: 2vw;
      margin-bottom: 2vw;
      input {
        width: 100%;
        font-family: league-gothic;
        text-transform: uppercase;
        border: none;
        outline: none;
        font-size: 1.4vw;
        line-height: 1.5;
        padding: 1vw 1vw .5vw 1vw;
        color: black;
        border-bottom: .25vw solid #0056B8;
        &::placeholder {
          color: rgba(109, 109, 109, .5);
        }
        opacity: 0;
        transform: translateY(100px);
        transition: all .7s ease-in-out;
        -webkit-appearance: none;
      }
    }
    .message {
      width: 100%;
      padding-right: 2vw;
      textarea {
        font-family: league-gothic;
        text-transform: uppercase;
        width: 100%;
        border: none;
        outline: none;
        font-size: 1.4vw;
        line-height: 1.5;
        padding: 1vw 1vw .5vw 1vw;
        color: black;
        border-bottom: .25vw solid #0056B8;
        &::placeholder {
          color: rgba(109, 109, 109, .5);
        }
        opacity: 0;
        transform: translateY(100px);
        transition: all .7s ease-in-out;
        -webkit-appearance: none;
      }
    }
    .ctaContainer {
      display: flex;
      justify-content: left;
      position: relative;
      width: 100%;
      margin-top: 4vw;
      margin-bottom: 4vw;
      padding-right: 10%;
      opacity: 0;
      transform: translateY(100px);
      transition: all .7s ease-in-out;
      .cta {
        position: relative;
        cursor: pointer;
        padding: 0;
        .buttonLabel {
          font-family: league-gothic;
          font-size: 1.4vw;
          text-transform: uppercase;
          transition: all ease-in .2s;
          color: black;
          line-height: 1.5;
        }
        .arrow {
          position: absolute;
          right: -5vw;
          top: -3.7vw;
          height: 8vw;
          width: 2vw;
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDojMDkxQzNEOyIvPgogICAgPC9nPgo8L3N2Zz4K");
          background-position: center left;
          transform: rotate(-90deg);
          background-repeat: no-repeat no-repeat;
          z-index: 2;
          background-size: contain;
        }
        &:hover {
          .buttonLabel {
            color: rgba(0, 86, 184, 1);
          }
          .arrow {
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIzIDM2OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij4KICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDcuMzM1MzRlLTE2LDExLjk3OTUsLTAuMDYwNDkwOSwzLjcwNGUtMTgsMTA4LjkyOSwtNDM2NC4wNCkiPgogICAgICAgIDxwYXRoIGQ9Ik0zNjQuMjkyLDE2NjIuOTNMMzY0LjI5MiwxNTcyLjQ3TDM5My41NTQsMTU3Mi40N0wzOTMuNTU0LDE0MzQuNjVMMzk1LjA1NSwxNjE3LjdMMzkzLjU1NCwxODAwLjc1TDM5My41NTQsMTY2Mi45M0wzNjQuMjkyLDE2NjIuOTNaIiBzdHlsZT0iZmlsbDojMDQ2NmIyOyIvPgogICAgPC9nPgo8L3N2Zz4K");
          }
        }
      }
    }
  }
  &.show {
    .heading {
      opacity: 1;
      transform: translate(0,0);
    }
    form {
      .textField {
        input {
          opacity: 1;
          transform: translate(0,0);
        }
      }
      .message {
        textarea {
          opacity: 1;
          transform: translate(0,0);
        }
      }
      .ctaContainer {
        opacity: 1;
        transform: translate(0,0);
      }
    }
  }
  @media (max-width: 500px) {
    padding-top: 15%;
    .heading {
      font-size: 6vw;
      padding: 0 8%;
    }
    form {
      padding: 0 10%;
      .textField {
        width: 100%;
        padding-right: 0;
        margin-bottom: 6vw;
        input {
          font-size: 5vw;
          border-bottom: .5vw solid #0056B8;
          padding: 2vw 2vw 1vw 2vw;
        }
      }
      .message {
        padding-right: 0;
        textarea {
          font-size: 5vw;
          border-bottom: .5vw solid #0056B8;
          padding: 2vw 2vw 1vw 2vw;
        }
      }
      .ctaContainer {
        margin-top: 15vw;
        .cta {
          .buttonLabel {
            font-size: 6vw;
          }
          .arrow {
            right: -13vw;
            top: -7vw;
            height: 22vw;
          }
        }
      }
    }
  }
}