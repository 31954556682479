.table {
  padding: 5vw 5vw;
  width: 100%;
  position: relative;
  .topRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3vw;
    .heading {
      font-family: league-gothic;
      font-size: 1.7vw;
      color: black;
      letter-spacing: -.1vw;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
      line-height: 1.5;
    }
    .search {
      font-family: myriad-pro;
      font-size: 1vw;
      padding: 0.75vw 1vw;
      width: 22vw;
      border: none;
      background-color: rgba(216, 216, 216, .38);
      margin: 0;
      background-size: 1vw;
      background-image: url("data:image/svg+xml;base64, PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogc2tldGNodG9vbCA1MS4yICg1NzUxOSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+ODc3MTIyQkEtRTBDNy00QjVELUFBOEQtNUZBOTg0MDc5RDhFPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBza2V0Y2h0b29sLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgPGcgaWQ9ImZlYXRoZXItaWNvbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTk5Ni4wMDAwMDAsIC02MDguMDAwMDAwKSIgc3Ryb2tlPSIjMDQ2NmIyIiBzdHJva2Utd2lkdGg9IjIiPgogICAgICAgICAgICA8ZyBpZD0ic2VhcmNoIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg5OTcuMDAwMDAwLCA2MDkuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8Y2lyY2xlIGlkPSJPdmFsIiBjeD0iNy41IiBjeT0iNy41IiByPSI3LjUiPjwvY2lyY2xlPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTE4LDE4IEwxMi44LDEyLjgiIGlkPSJTaGFwZSI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=");
      background-repeat: no-repeat;
      background-position: 95%;
    }
  }
  .tableContainer {
    .titleRow {
      display: flex;
      width: 100%;
      border-bottom: .2vw solid #979797;
      padding-bottom: 1vw;
      .title {
        flex: 1;
        font-family: myriad-pro;
        font-weight: 900;
        color: black;
        font-size: 1.1vw;
        padding-right: 2vw;
      }
    }
    .dataRow {
      display: flex;
      width: 100%;
      border-bottom: .2vw solid #979797;
      padding: 2vw 0;
      transition: all .5s ease-in-out;
      opacity: 0;
      transform: translateX(100px);
      .rowItem {
        flex: 1;
        padding-right: 4%;
        align-items: center;
        a, div {
          display: flex;
          align-items: center;
          height: 100%;
          font-family: myriad-pro;
          font-size: 1vw;
          color: #7C7C7C;
          margin: 0;
          line-height: 1.5;
        }
        a {
          cursor: pointer;
          transition: all .25s ease-in-out;
          &:hover {
            color: black;
          }
        }
        &:first-of-type {
          a, div {
            color: #6D6D6D;
            font-weight: bold;
          }
        }
        &.button {
          a, div {
            background-color: #0056B8;
            justify-content: center;
            color: white;
            font-weight: bold;
            width: fit-content;
            padding: .75vw 3vw;
            cursor: pointer;
            transition: all .25s ease-in-out;
            &:hover {
              background-color: black;
            }
          }
        }
      }
    }
  }
  .mobile {
    display: none;
  }
  &.show {
    .tableContainer {
      .dataRow {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  @media (max-width: 500px) {
    .topRow {
      width: unset;
      display: block;
      .heading {
        font-size: 6vw;
      }
      .search {
        font-size: 4vw;
        background-size: 3vw;
        width: 80vw;
        padding: 3vw 2vw;
      }
    }
    .tableContainer {
      display: none;
    }
    .mobile {
      display: block;
      .dataRow {
        width: 100%;
        border-bottom: .2vw solid #979797;
        padding: 2vw 0;
        transition: all .5s ease-in-out;
        opacity: 0;
        transform: translateX(100px);
        .rowItem {
          padding-right: 4%;
          display: flex;
          margin: 0 0 5vw 0;
          .columnTitle {
            background-color: white;
          }
          a, div {
            height: 100%;
            font-family: myriad-pro;
            font-size: 4vw;
            color: #7C7C7C;
            line-height: 1.5;
            flex: 1;
          }
          a {
            cursor: pointer;
            transition: all .25s ease-in-out;
            &:hover {
              color: black;
            }
          }
          &:first-of-type {
            a, div {
              color: #6D6D6D;
              font-weight: bold;
            }
          }
          &.button {
            .columnValue {
              div, a {
                background-color: #0056B8;
                justify-content: center;
                color: white;
                font-weight: bold;
                width: fit-content;
                padding: .75vw 3vw;
                cursor: pointer;
                transition: all .25s ease-in-out;
                &:hover {
                  background-color: black;
                }
              }
            }
          }
        }
      }
    }
    &.show {
      .mobile {
        .dataRow {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }
}