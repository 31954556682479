.topLinks {
  display: grid;
  grid-auto-flow: column;
  gap: 1vw;
  align-items: center;
  .link {
    text-transform: uppercase;
    color: #a5a5a5;
    font-size: .87vw;
    font-family: myriad-pro;
    font-weight: 900;
    transition: all 0.25s ease-in-out;
    &.active, &:hover {
      color: black;
    }
  }
  @media (max-width: 500px) {
    display: none;
  }
}