.textField {
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  padding-right: 2vw;
  label {
    font-family: league-gothic;
    color: #9E9E9E;
    font-size: 1.3vw;
    font-weight: 500;
    line-height: 1.5;
  }
  input {
    border: none;
    border-bottom: .25vw solid black;
    font-family: myriad-pro;
    font-weight: 500;
    font-size: 1.4vw;
    letter-spacing: -.1vw;
    color: black;
    padding: 1vw 1vw .5vw 1vw;
    -webkit-appearance: none;
  }
  @media (max-width: 500px) {
    label {
      font-size: 4vw;
    }
    input {
      font-size: 4vw;
    }
  }
}